import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const DetailPage = ({ handlePageChange, detailId, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [armada, setArmada] = useState({});
    const [vendorArmada, setVendorArmada] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [jenisMobil, setJenisMobil] = useState([]);
    const [selectedJenisMobil, setSelectedJenisMobil] = useState(null);
    const [formData, setFormData] = useState({
        id_armada: armada?.id_armada || "",
        id_vendor: armada?.id_vendor || "",
        id_jenis_mobil: armada?.id_jenis_mobil || "",
        nopol_mobil_armada: armada?.nopol_mobil_armada || "",
        status_armada: armada?.status_armada || "TERSEDIA",
        lokasi_terakhir: armada?.lokasi_terakhir || ""
    });

    useEffect(() => {
        setFormData({
            id_armada: armada?.id_armada || "",
            id_vendor: armada?.id_vendor || "",
            id_jenis_mobil: armada?.id_jenis_mobil || "",
            nopol_mobil_armada: armada?.nopol_mobil_armada || "",
            status_armada: armada?.status_armada || "TERSEDIA",
            lokasi_terakhir: armada?.lokasi_terakhir || ""
        });
    }, [armada]);

    useEffect(() => {
        const fetchArmadaAll = async () => {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            try {
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/armada/${detailId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setArmada(response.data);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmadaAll();
    }, [token, detailId, navigate]);

    useEffect(() => {
        const fetchVendorArmadaAll = async () => {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            try {
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datavendorarmada = response.data.map(vendorarmadaall => ({
                    value: vendorarmadaall.id_vendor,
                    label: vendorarmadaall.nama_vendor
                }));
                setVendorArmada(datavendorarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchVendorArmadaAll();
    }, [token, detailId, navigate]);

    useEffect(() => {
        const fetchJenisMobil = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/jenis_mobil', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datajenismobil = response.data.map(jenismobil => ({
                    value: jenismobil.id_jenis_mobil,
                    label: jenismobil.nama_jenis_mobil
                }));
                setJenisMobil(datajenismobil);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchJenisMobil();
    }, [token, detailId, navigate]);

    useEffect(() => {
        if (detailId !== '') {
            console.log(detailId);
            handlePageChange('detail', detailId);
        }
    }, [detailId, handlePageChange]);

    const handleVendorArmadaChange = (selectedOption) => {
        let name = "id_vendor";
        setFormData({
            ...formData,
            [name]: selectedOption.value
        });
        setSelectedVendor(selectedOption);
    };

    const handleJenisMobilChange = (selectedOption) => {
        let name = "id_jenis_mobil";
        setFormData({
            ...formData,
            [name]: selectedOption.value
        });
        setSelectedJenisMobil(selectedOption);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.clear();
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.put(`https://api.midstunting.delapandelapanlogistics.com/api/armada/${detailId}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Armada',
                text: 'Data Berhasil Diperbaharui',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                handleBackClick();
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'Session expired, please login again',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                });
            }
        }
    };
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Detail Armada</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Armada.
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_vendor" className="form-label">VENDOR ARMADA</label>
                            <Select
                                id="id_vendor"
                                name="id_vendor"
                                value={selectedVendor}
                                onChange={handleVendorArmadaChange}
                                options={vendorArmada}
                                placeholder="PILIH VENDOR"
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_jenis_mobil" className="form-label">JENIS MOBIL</label>
                            <Select
                                id="id_jenis_mobil"
                                name="id_jenis_mobil"
                                value={selectedJenisMobil}
                                onChange={handleJenisMobilChange}
                                options={jenisMobil}
                                placeholder="PILIH JENIS MOBIL"
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="vendor_lama" className="form-label">Vendor Armada</label>
                            <input className="form-control text-uppercase" type="text" id="vendor_lama" name='vendor_lama' placeholder="" value={armada.nama_vendor} readOnly />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="jenis_mobil_lama" className="form-label">Jenis Mobil</label>
                            <input className="form-control text-uppercase" type="text" id="jenis_mobil_lama" name='jenis_mobil_lama' placeholder="Nopol Mobil" value={armada.nama_jenis_mobil} readOnly />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="nopol_mobil_armada" className="form-label">Nopol Mobil</label>
                            <input className="form-control text-uppercase" type="text" id="nopol_mobil_armada" name='nopol_mobil_armada' placeholder="Nopol Mobil" ref={inputRef} defaultValue={armada?.nopol_mobil_armada || ""} onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="status_armada" className="form-label">Status</label>
                            <input className="form-control text-uppercase" type="text" id="status_armada" name='status_armada' placeholder="Tersedia" value={armada.status_armada} readOnly />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="status_armada" className="form-label">Lokasi Terakhir</label>
                            <input className="form-control text-uppercase" type="text" id="status_armada" name='status_armada' placeholder="Tersedia" value={armada.lokasi_terakhir} readOnly />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">SIMPAN</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

DetailPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    // handleBackClick: PropTypes.func.isRequired,
    detailId: PropTypes.number.isRequired,
};

export default DetailPage;
