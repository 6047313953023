import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Layout from './layouts/Layout';
import AppRoutes from './router/AppRoutes';
import Login from './pages/auth/LoginPage'; // Import halaman Login

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    if (token && role) {
      setIsLoggedIn(true);
      setUserRole(role);
    } else {
      setIsLoggedIn(false);
    }
  }, [location]);

  const isAuthPath = location.pathname.includes('login');

  // Jika belum login dan mencoba mengakses halaman selain login, arahkan ke halaman login
  if (!isLoggedIn && !isAuthPath) {
    return <Navigate to="/login" />;
  }

  // Jika sudah login dan mencoba mengakses halaman login, arahkan ke halaman dashboard
  if (isLoggedIn && isAuthPath) {
    return <Navigate to={`/${userRole}/dashboard`} />;
  }

  return (
    <>
      {!isAuthPath ? (
        <Layout>
          <AppRoutes />
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
