import React, { useState } from 'react';

const DashboardPage = () => {
    // console.clear();
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="mb-3">
                        <div className="divider text-start">
                            <div className="divider-text">
                                <span className="menu-header-text fs-6">Dashboard</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DashboardPage;