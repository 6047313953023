import React, { useState, useEffect } from 'react';
import AddPage from './addPage';
import DetailPage from './detailPage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const IndexLOPage = () => {
    const token = localStorage.getItem('token');
    const id_user = localStorage.getItem('id_user');
    const id_kantor = localStorage.getItem('id_kantor');
    const navigate = useNavigate();
    const [currentView, setCurrentView] = useState('index');
    const [detailId, setDetailId] = useState(null);
    const [detailIdMove, setDetailIdMove] = useState(null);
    const [idAlokasi, setIdAlokasi] = useState(null);
    const [detailIdDriver, setDetailIdDriver] = useState(null);
    const [detailIdArmada, setDetailIdArmada] = useState(null);
    const [statusLOInit, setStatusLOInit] = useState(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(null);
    const [selectedStatusArmada, setSelectedStatusArmada] = useState(null);
    const [loFiltered, setLOFiltered] = useState([]);
    const [tanggalAwal, setTanggalAwal] = useState(null);
    const [tanggalAkhir, setTanggalAkhir] = useState(null);
    const [formFilter] = useState({});
    const [statusArmada] = useState([
        { value: 'ALL', label: 'TAMPILKAN SEMUA' },
        { value: 'MENUNGGU', label: 'MENUNGGU' },
        { value: 'LOADING', label: 'LOADING' },
        { value: 'SELESAI', label: 'SELESAI' },
        { value: 'DIBATALKAN', label: 'DIBATALKAN' },
        { value: 'REDELIVER', label: 'REDELIVER' }
    ]);
    let fiterLengkap = true;
    let nomor = 1;

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    const handlePageChange = (page, id = null, idalokasi = null, statuslo, iddriver, idarmada, idmove) => {
        setCurrentView(page);
        if (id !== null) {
            setDetailId(id);
            setIdAlokasi(idalokasi);
            setStatusLOInit(statuslo);
            setDetailIdDriver(iddriver);
            setDetailIdArmada(idarmada);
            setDetailIdMove(idmove);
        }
    };

    const handleBackClick = () => {
        setCurrentView('index');
        showFilter("ALL", "ALL", "ALL");
    };

    const handleAlokasiChange = (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const handleStatusArmadaChange = (selectedOption) => {
        setSelectedStatusArmada(selectedOption);
    };

    const handleTanggalAwalChange = (e) => {
        const { value } = e.target;
        setTanggalAwal(value);
    };

    const handleTanggalAkhirChange = (e) => {
        const { value } = e.target;
        setTanggalAkhir(value);
    };

    const showFilter = async (status, alokasi) => {
        if (!status) {
            fiterLengkap = false;
        }
        if (!alokasi) {
            fiterLengkap = false;
        }
        if (!fiterLengkap) {
            Swal.fire({
                title: 'Filter Data',
                text: 'Pilih Alokasi dan Status Terlebih Dahulu',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
        } else {
            const dataToSubmit = ({
                ...formFilter,
                tanggal_awal: tanggalAwal,
                tanggal_akhir: tanggalAkhir,
                id_kantor: id_kantor,
                status_lo: status.value
            });
            let link_lo = "";
            if (selectedAlokasi.value === 4) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
            } else if (selectedAlokasi.value === 5) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
            } else if (selectedAlokasi.value === 6) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
            } else {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
            }
            try {
                const response = await axios.put(`${link_lo}/filter/data`, dataToSubmit, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datalofiltered = response.data.map(datalo => ({
                    id_lo: datalo.id_lo,
                    id_alokasi: selectedAlokasi.value,
                    tanggal_lo: datalo.tanggal_lo,
                    tanggal_muat: datalo.tanggal_muat,
                    tanggal_selesai: datalo.tanggal_selesai,
                    titik_muat: datalo.titik_muat,
                    destination: datalo.destination,
                    status_lo: datalo.status_lo,
                    nomor_lo: datalo.nomor_lo,
                    id_driver: datalo.id_driver,
                    id_armada: datalo.id_armada,
                    id_move: datalo.id_move,
                }));
                setLOFiltered(datalofiltered);
                if (response.data.length === 0) {
                    Swal.fire({
                        title: 'Data Loading Order (LO)',
                        text: 'Data Loading Order (LO) Tidak Ditemukan',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        position: 'center',
                        timerProgressBar: true
                    });
                    setLOFiltered([]);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Data Loading Order (LO)',
                    text: 'Data Loading Order (LO) Tidak Ditemukan',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    position: 'center',
                    timerProgressBar: true
                });
                setLOFiltered([]);
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            {currentView === 'index' && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="divider text-start">
                                <div className="divider-text">
                                    <span className="menu-header-text fs-6 fw-bold">Data Loading Order (LO)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="">
                            Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('add')}>disini</button> untuk menambahkan data Loading Order (LO).
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label htmlFor="tanggal_awal" className="form-label">Tanggal (Awal)</label>
                                <input className="form-control text-uppercase" type="date" id="tanggal_awal" name='tanggal_awal' placeholder="Nopol Mobil" onChange={handleTanggalAwalChange} required />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label htmlFor="tanggal_akhir" className="form-label">Tanggal (Akhir)</label>
                                <input className="form-control text-uppercase" type="date" id="tanggal_akhir" name='tanggal_akhir' placeholder="Nopol Mobil" onChange={handleTanggalAkhirChange} required />
                            </div>
                            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                                <label className="form-label">Alokasi</label>
                                <Select
                                    value={selectedAlokasi}
                                    onChange={handleAlokasiChange}
                                    options={alokasiOption}
                                    placeholder="PILIH ALOKASI"
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                                <label className="form-label">STATUS</label>
                                <Select
                                    value={selectedStatusArmada}
                                    onChange={handleStatusArmadaChange}
                                    options={statusArmada}
                                    placeholder="PILIH STATUS"
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label className="form-label">FILTER</label>
                                <button type="button" className="btn btn-primary w-100" onClick={() => showFilter(
                                    selectedStatusArmada,
                                    selectedAlokasi
                                )} >TAMPILKAN</button>
                            </div>
                            <div className="col-md-12 mb-4 mb-md-0">
                                <div className="accordion mt-3" id="accordion_armada">
                                    {loFiltered.map(va => (
                                        <div key={va.id_lo} className="card accordion-item">
                                            <h2 className="accordion-header px-2" id={`heading${va.id_lo}`}>
                                                <button
                                                    type="button"
                                                    className={`accordion-button accordion-button-primary collapsed ${(va.status_lo === "DIBATALKAN") ? 'text-danger' : 'text-primary'}`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordion${va.id_lo}`}
                                                    aria-expanded="false"
                                                    aria-controls={`accordion${va.id_lo}`}
                                                >
                                                    {va.nomor_lo} | {formatDate(va.tanggal_lo)} | {va.titik_muat} | {va.status_lo}
                                                </button>
                                            </h2>
                                            <div id={`accordion${va.id_lo}`} className="accordion-collapse collapse" data-bs-parent="#accordion_armada">
                                                <div className="accordion-body" style={{ marginTop: "-15px" }} >
                                                    <div className="px-2">
                                                        <hr />
                                                        <div className="col-md-12 col-sm-12 mt-0 mt-md-3">
                                                            <p style={{ marginBottom: "2px" }}>
                                                                Tanggal Loading Order (LO) {formatDate(va.tanggal_lo)}
                                                            </p>
                                                            <p style={{ marginBottom: "2px" }}>
                                                                Dari {va.titik_muat}
                                                            </p>
                                                            <p style={{ marginBottom: "2px" }}>
                                                                Status <span className='fw-bold' >{va.status_lo}</span>
                                                            </p>
                                                            <button className="btn btn-link p-0 mt-3" onClick={() => handlePageChange('detail', va.id_lo, va.id_alokasi, va.status_lo, va.id_driver, va.id_armada, va.id_move)}>
                                                                <i className="tf-icons bx bx-edit me-2"></i> DETAIL
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentView === 'add' && <AddPage handlePageChange={handlePageChange} handleBackClick={handleBackClick} detailId={detailId} />}
            {currentView === 'detail' && <DetailPage handlePageChange={handlePageChange} detailId={detailId} idAlokasi={idAlokasi} statusLOInit={statusLOInit} detailIdDriver={detailIdDriver} detailIdArmada={detailIdArmada} detailIdMove={detailIdMove} handleBackClick={handleBackClick} />}
        </div>
    );
};

export default IndexLOPage;