import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";
import QRCode from 'qrcode';


const DetailPage = ({ handlePageChange, detailId, idAlokasi, statusPOInit, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const id_kantor = localStorage.getItem('id_kantor');
    const id_user = localStorage.getItem('id_user');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const initialAlokasiValue = alokasiOption.find(option => option.value === idAlokasi) || null;
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };
    const [po, setPO] = useState({
        id_po: "",
        id_kantor: "",
        id_user: "",
        tanggal_po: "",
        tanggal_muat: "",
        tanggal_selesai: "",
        origin: "",
        destination: "",
        status_po: "",
        kode_kantor: "",
        nomor_po: ""
    });
    const [itemPO, setItemPO] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(initialAlokasiValue);
    const [statusPO] = useState([
        { value: 'MENUNGGU', label: 'MENUNGGU' },
        { value: 'LOADING', label: 'LOADING' },
        { value: 'SELESAI', label: 'SELESAI' },
        { value: 'DIBATALKAN', label: 'DIBATALKAN' },
    ]);
    const [jenisMuatanOption] = useState([
        { value: 'AYAM', label: 'AYAM' },
        { value: 'TELUR', label: 'TELUR' },
        { value: 'MIX', label: 'MIX' }
    ]);
    const [selectedJenisMuatan, setSelectedJenisMuatan] = useState(null);
    let initialStatusValue = statusPO.find(option => option.value === statusPOInit) || null;
    const [selectedStatusPO, setSelectedStatusPO] = useState(initialStatusValue);
    const [armadaOption, setArmadaOption] = useState([]);
    const [selectedArmada, setSelectedArmada] = useState(null);
    const [driverOption, setDriverOption] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [formData, setFormData] = useState({
        id_po: po?.id_po || "",
        id_kantor: po?.id_kantor || "",
        id_user: po?.id_user || "",
        tanggal_po: po?.tanggal_po || "",
        tanggal_muat: po?.tanggal_muat || "",
        tanggal_selesai: po?.tanggal_selesai || "",
        origin: po?.origin || "",
        destination: po?.destination || "",
        status_po: po?.status_po || "",
        kode_kantor: po?.kode_kantor || "",
        nomor_po: po?.nomor_po || ""
    });
    const [formDataTambahArmada, setFormDataTambahArmada] = useState({});

    useEffect(() => {
        setFormData({
            id_po: po?.id_po || "",
            id_kantor: po?.id_kantor || "",
            id_user: po?.id_user || "",
            tanggal_po: po?.tanggal_po || "",
            tanggal_muat: po?.tanggal_muat || "",
            tanggal_selesai: po?.tanggal_selesai || "",
            origin: po?.origin || "",
            destination: po?.destination || "",
            status_po: po?.status_po || "",
            kode_kantor: po?.kode_kantor || "",
            nomor_po: po?.nomor_po || ""
        });
    }, [po]);

    const fetchPO = async () => {
        if (!token) {
            Swal.fire({
                title: 'Session Expired',
                text: 'Please login to continue',
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate('/login');
            });
            return;
        }
        try {
            let nomor = 1;
            let link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po4";
            if (idAlokasi == 4) {
                link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po4";
            } else if (idAlokasi == 5) {
                link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po5";
            } else if (idAlokasi == 6) {
                link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
            } else {
                link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
            }
            const response = await axios.get(`${link_po}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const dataitempo = response.data.result.items.map(itempoall => ({
                nomor: nomor++,
                id_alokasi: idAlokasi,
                id_item_po: itempoall.id_item_po,
                id_po: itempoall.id_po,
                id_armada: itempoall.id_armada,
                id_driver: itempoall.id_driver,
                jenis_muatan: itempoall.jenis_muatan,
                jumlah_muatan: itempoall.jumlah_muatan,
                nopol_mobil_armada: itempoall.armada.nopol_mobil_armada,
                nama_driver: itempoall.driver.nama_driver,
                nomor_telpon: itempoall.driver.nomor_telpon,
                nama_vendor: itempoall.vendor_armada.nama_vendor,
                nama_jenis_mobil: itempoall.jenis_mobil.nama_jenis_mobil
            }));
            setPO(response.data.result);
            setItemPO(dataitempo);
        } catch (error) {
            console.error('Error fetching', error);
        }
    };

    useEffect(() => {
        fetchPO();
    }, [detailId, idAlokasi]);

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        const fetchArmada = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataarmada = response.data.map(armadaall => ({
                    value: armadaall.id_armada,
                    label: armadaall.nopol_mobil_armada + " - " + armadaall.nama_jenis_mobil
                }));
                setArmadaOption(dataarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmada();
    }, [token, navigate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/driver', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const daradriver = response.data.map(driver => ({
                    value: driver.id_driver,
                    label: driver.nama_driver + " (" + driver.nomor_telpon + ")"
                }));
                setDriverOption(daradriver);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchDriver();
    }, [token, navigate]);

    useEffect(() => {
        setSelectedAlokasi(initialAlokasiValue);
    }, [alokasiOption, initialAlokasiValue]);

    useEffect(() => {
        setSelectedStatusPO(initialStatusValue);
    }, [alokasiOption, initialStatusValue]);

    const generateQRCode = async (text) => {
        try {
            const canvas = await QRCode.toCanvas(text);
            return canvas.toDataURL('image/png');
        } catch (error) {
            console.error('Error generating QR code:', error);
            return '';
        }
    };

    const handleAlokasiChange = (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const handleArmadaChange = (selectedOption) => {
        setSelectedArmada(selectedOption);
    };

    const handleDriverChange = (selectedOption) => {
        setSelectedDriver(selectedOption);
    };

    const handleJenisMuatanChange = (selectedOption) => {
        setSelectedJenisMuatan(selectedOption);
    };

    const handleStatusPOChange = (selectedOption) => {
        setSelectedStatusPO(selectedOption);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDataArmadaChange = (e) => {
        const { name, value } = e.target;
        setFormDataTambahArmada({
            ...formDataTambahArmada,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.clear();
        console.log(formData);
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.put(`https://api.midstunting.delapandelapanlogistics.com/api/po6/${detailId}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Purchae Order (PO)',
                text: 'Data Berhasil Diperbaharui',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                handleBackClick();
            });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'Session expired, please login again',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                });
            }
        }
    };

    const handleTambahArmada = async () => {
        if (!selectedArmada || !selectedDriver || !selectedJenisMuatan || formDataTambahArmada.jumlah_muatan == undefined) {
            Swal.fire({
                title: 'Data Purchase Order (PO)',
                text: 'Mohon lengkapi data terlebih dahulu',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
        } else {
            const dataToSubmit = {
                ...formDataTambahArmada,
                id_po: detailId,
                id_armada: selectedArmada.value,
                id_driver: selectedDriver.value,
                jenis_muatan: selectedJenisMuatan.value
            };
            console.log(dataToSubmit);
            let link_item_po = "";
            if (idAlokasi == 4) {
                link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po4";
            } else if (idAlokasi == 5) {
                link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po5";
            } else if (idAlokasi == 6) {
                link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po6";
            } else {
                link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po6";
            }
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                await axios.post(link_item_po, dataToSubmit,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                Swal.fire({
                    title: 'Data Purchase Order (PO)',
                    text: 'Data Berhasil Ditambahkan',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    position: 'center',
                    timerProgressBar: true
                });
                fetchPO();
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        }
    };

    const handleDeleteItemPO = async (iditempo) => {
        let link_item_po = "";
        if (idAlokasi == 4) {
            link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po4";
        } else if (idAlokasi == 5) {
            link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po5";
        } else if (idAlokasi == 6) {
            link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po6";
        } else {
            link_item_po = "https://api.midstunting.delapandelapanlogistics.com/api/item_po6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.delete(link_item_po + '/' + iditempo,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Purchase Order (PO)',
                text: 'Data Berhasil Dihapus',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
            fetchPO();
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const generatePDF = async () => {
        const tanggaljadi = po.tanggal_po.slice(8, 10);
        const bulanjadi = po.tanggal_po.slice(5, 7);
        const tahunjadi = po.tanggal_po.slice(0, 4);
        const tanggaljadimuat = po.tanggal_muat.slice(8, 10);
        const bulanjadimuat = po.tanggal_muat.slice(5, 7);
        const tahunjadimuat = po.tanggal_muat.slice(0, 4);
        const doc = new jsPDF();
        let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 50, 13);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        let title1 = 'PT DELAPAN DELAPAN LOGISTICS';
        let title2 = 'Jl. Raya Sidaraja – Lebaksiuh No. 81 Blok Jati Kidul Dusun Manis RT. 001 RW. 001';
        let title3 = 'Desa Sidaraja Kecamatan Ciawigebang Kabupaten Kuningan – JAWA BARAT (45911)';
        let title4 = 'Kontak Person : WA 082-217-740-459, Email : delapandelapanlogistics@gmail.com';
        doc.text(title1, 120, 13, { align: 'center' });
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.text(title2, 120, 18, { align: 'center' });
        doc.text(title3, 120, 22, { align: 'center' });
        doc.text(title4, 120, 26, { align: 'center' });
        doc.setLineWidth(1);
        doc.line(10, 33, 200, 33);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text("Purchase Order (PO)", 105, 45, { align: 'center' });
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text("Nomor PO", 10, 55);
        doc.text(": " + po.nomor_po, 40, 55);
        doc.text("Tanggal PO :", 10, 60);
        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 40, 60);
        doc.text("Tanggal Muat :", 10, 65);
        doc.text(": " + tanggaljadimuat + "/" + bulanjadimuat + "/" + tahunjadimuat, 40, 65);
        doc.text("Customer", 10, 70);
        doc.text(": PT BGR Logistics", 40, 70);
        doc.text("Origin", 10, 75);
        doc.text(": " + po.origin, 40, 75);
        doc.text("Destination", 10, 80);
        doc.text(": " + po.destination, 40, 80);
        doc.setLineWidth(0.2);
        doc.rect(10, 90, 10, 10);
        doc.rect(20, 90, 25, 10);
        doc.rect(45, 90, 25, 10);
        doc.rect(70, 90, 40, 10);
        doc.rect(110, 90, 30, 10);
        doc.rect(140, 90, 30, 10);
        doc.rect(170, 90, 30, 10);
        doc.text("NO", 12, 96);
        doc.text("NOPOL", 22, 96);
        doc.text("JENIS MOBIL", 47, 96);
        doc.text("NAMA DRIVER", 72, 96);
        doc.text("TELPON", 112, 96);
        doc.text("JENIS MUATAN", 142, 96);
        doc.text("MUATAN", 172, 96);
        let ytabel = 90;
        let ykonten = 96;
        for (let i = 0; i < itemPO.length; i++) {
            ykonten = ykonten + 10;
            ytabel = ytabel + 10;
            doc.rect(10, ytabel, 10, 10);
            doc.rect(20, ytabel, 25, 10);
            doc.rect(45, ytabel, 25, 10);
            doc.rect(70, ytabel, 40, 10);
            doc.rect(110, ytabel, 30, 10);
            doc.rect(140, ytabel, 30, 10);
            doc.rect(170, ytabel, 30, 10);
            doc.text(String(itemPO[i].nomor), 12, ykonten);
            doc.text(String(itemPO[i].nopol_mobil_armada), 22, ykonten);
            doc.text(String(itemPO[i].nama_jenis_mobil), 47, ykonten);
            doc.text(String(itemPO[i].nama_driver), 72, ykonten);
            doc.text(String(itemPO[i].nomor_telpon), 112, ykonten);
            doc.text(String(itemPO[i].jenis_muatan), 142, ykonten);
            doc.text(String(itemPO[i].jumlah_muatan), 172, ykonten);
        }
        let qrImageDataBASTDTT = await generateQRCode(po.nomor_po);
        doc.addImage(qrImageDataBASTDTT, 'PNG', 8, 36, 15, 15);
        doc.save(po.nomor_po);
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Detail Purchase Order (PO)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Purchase Order (PO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                            <Select
                                id="id_alokasi"
                                name="id_alokasi"
                                value={selectedAlokasi}
                                onChange={handleAlokasiChange}
                                options={alokasiOption}
                                placeholder="PILIH ALOKASI"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_po" className="form-label">Tanggal PO</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_po" name='tanggal_po' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={po ? po.tanggal_po.slice(0, 10) : ""} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_muat" className="form-label">Tanggal Muat</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_muat" name='tanggal_muat' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={po ? po.tanggal_muat.slice(0, 10) : ""} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_selesai" className="form-label">Estimasi Tanggal Selesai</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_selesai" name='tanggal_selesai' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={po ? po.tanggal_selesai.slice(0, 10) : ""} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="origin" className="form-label">Origin</label>
                            <input className="form-control text-uppercase" type="text" id="origin" name='origin' placeholder="ORIGIN" onChange={handleChange} ref={inputRef} defaultValue={po?.origin || ""} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input className="form-control text-uppercase" type="text" id="destination" name='destination' placeholder="DESTINATION" onChange={handleChange} ref={inputRef} defaultValue={po?.destination || ""} required />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="status_po" className="form-label">Status PO</label>
                            <Select
                                id="status_po"
                                name="status_po"
                                value={selectedStatusPO}
                                onChange={handleStatusPOChange}
                                options={statusPO}
                                placeholder="PILIH STATUS PO"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">SIMPAN</button>
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="button" className="btn btn-primary w-100" onClick={() => generatePDF()} >DOWNLOAD</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            Tambah Armada (Optional)
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="id_armada" className="form-label">ARMADA</label>
                <Select
                    id="id_armada"
                    name="id_armada"
                    value={selectedArmada}
                    onChange={handleArmadaChange}
                    options={armadaOption}
                    placeholder="PILIH ARMADA"
                    required
                />
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="id_driver" className="form-label">DRIVER</label>
                <Select
                    id="id_driver"
                    name="id_driver"
                    value={selectedDriver}
                    onChange={handleDriverChange}
                    options={driverOption}
                    placeholder="PILIH DRIVER"
                    required
                />
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="jenis_muatan" className="form-label">JENIS MUATAN</label>
                <Select
                    id="jenis_muatan"
                    name="jenis_muatan"
                    value={selectedJenisMuatan}
                    onChange={handleJenisMuatanChange}
                    options={jenisMuatanOption}
                    placeholder="PILIH JENIS MUATAN"
                    required
                />
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
                <label htmlFor="jumlah_muatan" className="form-label">MUATAN</label>
                <input className="form-control text-uppercase" type="number" id="jumlah_muatan" name='jumlah_muatan' placeholder="0" onChange={handleDataArmadaChange} required />
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
                <label htmlFor="" className="form-label">Proses</label>
                <button className="btn btn-primary w-100" onClick={() => handleTambahArmada()} >TAMBAHKAN</button>
            </div>
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            List Armada
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-4 mb-md-0">
                <div className='table-responsive text-nowrap'>
                    <table className="table" style={{ fontSize: "13px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "5px" }} >No</th>
                                <th>NOPOL</th>
                                <th>JENIS MOBIL</th>
                                <th>NAMA DRIVER</th>
                                <th>NOMOR TELPON</th>
                                <th>JENIS MUATAN</th>
                                <th>MUATAN</th>
                                <th style={{ width: "5px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemPO.map((itempo, index) => (
                                <tr key={index}>
                                    <td>{itempo.nomor}</td>
                                    <td>{itempo.nopol_mobil_armada}</td>
                                    <td>{itempo.nama_jenis_mobil}</td>
                                    <td>{itempo.nama_driver}</td>
                                    <td>{itempo.nomor_telpon}</td>
                                    <td>{itempo.jenis_muatan}</td>
                                    <td>{itempo.jumlah_muatan}</td>
                                    <td>
                                        <button className="btn btn-link text-danger w-100 text-start" type="button" id="button-addon2" onClick={() => handleDeleteItemPO(itempo.id_item_po)}>  <i className="tf-icons bx bx-minus-circle me-2"></i> Hapus</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

DetailPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    detailId: PropTypes.number.isRequired,
    idAlokasi: PropTypes.number.isRequired,
    statusPOInit: PropTypes.string.isRequired,
};

export default DetailPage;
