import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";


const DetailPage = ({ handlePageChange, detailId, idAlokasi, statusLOInit, detailIdDriver, detailIdArmada, detailIdMove, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const id_kantor = localStorage.getItem('id_kantor');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [armadaOption, setArmadaOption] = useState([]);
    const [driverOption, setDriverOption] = useState([]);
    const [moveOption, setMoveOption] = useState([]);
    const [statusLO] = useState([
        { value: 'MENUNGGU', label: 'MENUNGGU' },
        { value: 'LOADING', label: 'LOADING' },
        { value: 'SELESAI', label: 'SELESAI' },
        { value: 'DIBATALKAN', label: 'DIBATALKAN' },
        { value: 'REDELIVER', label: 'REDELIVER' }
    ]);
    const initialAlokasiValue = alokasiOption.find(option => option.value === idAlokasi) || null;
    const initialStatusValue = statusLO.find(option => option.value === statusLOInit) || null;
    const initialIdDriverValue = driverOption.find(option => option.value === detailIdDriver) || null;
    const initialIdArmadaValue = armadaOption.find(option => option.value === detailIdArmada) || null;
    const initialIdMoveValue = moveOption.find(option => option.value === detailIdMove) || null;
    const [kabupatenOption, setKabupatenOption] = useState([]);
    const [selectedKabupaten, setSelectedKabupaten] = useState('');
    const [kecamatanOption, setKecamatanOption] = useState([]);
    const [selectedKecamatan, setSelectedKecamatan] = useState('');
    const [desaOption, setDesaOption] = useState([]);
    const [selectedDesa, setSelectedDesa] = useState([]);
    const [jenisMuatanOption] = useState([
        { value: 'AYAM', label: 'AYAM' },
        { value: 'TELUR', label: 'TELUR' },
        { value: 'MIX', label: 'MIX' }
    ]);
    const [selectedJenisMuatan, setSelectedJenisMuatan] = useState(null);
    const [lo, setLO] = useState({
        id_lo: "",
        id_kantor: "",
        id_user: "",
        id_driver: "",
        id_armada: "",
        nama_driver: "",
        nomor_telpon: "",
        nopol_mobil_armada: "",
        nama_jenis_mobil: "",
        tanggal_lo: "",
        titik_muat: "",
        status_lo: "",
        kode_kantor: "",
        nomor_lo: "",
        id_move: ""
    });

    const [itemLO, setItemLO] = useState([]);
    const [totalLO, setTotalLO] = useState(0);
    const [itemDokumenLO, setItemDokumenLO] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(initialAlokasiValue);
    const [selectedStatus, setSelectedStatus] = useState(initialStatusValue);
    const [selectedArmada, setSelectedArmada] = useState(initialIdDriverValue);
    const [selectedDriver, setSelectedDriver] = useState(initialIdArmadaValue);
    const [selectedMove, setSelectedMove] = useState(initialIdMoveValue);

    const [formData, setFormData] = useState({
        id_lo: lo?.id_lo || "",
        id_kantor: lo?.id_kantor || "",
        id_user: lo?.id_user || "",
        id_driver: lo?.id_driver || "",
        id_armada: lo?.id_armada || "",
        tanggal_lo: lo?.tanggal_lo || "",
        titik_muat: lo?.titik_muat || "",
        status_lo: lo?.status_lo || "",
        nomor_lo: lo?.nomor_lo || "",
        id_move: lo?.id_move || ""
    });

    const [formDataLOSJT, setFormDataLOSJT] = useState({});

    useEffect(() => {
        setFormData({
            id_lo: lo?.id_lo || "",
            id_kantor: lo?.id_kantor || "",
            id_user: lo?.id_user || "",
            id_driver: lo?.id_driver || "",
            id_armada: lo?.id_armada || "",
            tanggal_lo: lo?.tanggal_lo || "",
            titik_muat: lo?.titik_muat || "",
            status_lo: lo?.status_lo || "",
            nomor_lo: lo?.nomor_lo || "",
            id_move: lo?.id_move || ""
        });
    }, [lo]);

    const fetchMove = async () => {
        if (!token) {
            Swal.fire({
                title: 'Session Expired',
                text: 'Please login to continue',
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate('/login');
            });
            return;
        }
        let link_move = "";
        if (idAlokasi === 4) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
        } else if (idAlokasi === 5) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
        } else if (idAlokasi === 6) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        } else {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get(link_move + "/kantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const datamove = response.data.map(moveall => ({
                value: moveall.id_move,
                label: moveall.nomor_move
            }));
            setMoveOption(datamove);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    useEffect(() => {
        fetchMove();
    }, [idAlokasi]);

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    const handleMoveChange = (selectedOption) => {
        setSelectedMove(selectedOption);
    };

    const fetchLO = async () => {
        if (!token) {
            Swal.fire({
                title: 'Session Expired',
                text: 'Please login to continue',
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate('/login');
            });
            return;
        }
        try {
            let nomor = 1;
            let link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
            let link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo4/bahandokumen";
            if (idAlokasi === 4) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo4/bahandokumen";
            } else if (idAlokasi === 5) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo5/bahandokumen";
            } else if (idAlokasi === 6) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo6/bahandokumen";
            } else {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo6/bahandokumen";
            }
            const response = await axios.get(`${link_lo}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const response2 = await axios.get(`${link_lo_2}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            let bahandataitemlo = response.data.result.items.sort((a, b) => {
                if (a.titik_bagi < b.titik_bagi) return -1;
                if (a.titik_bagi > b.titik_bagi) return 1;
                return 0;
            });
            const dataitemlo = bahandataitemlo.map(itemloall => ({
                nomor: nomor++,
                id_item_lo: itemloall.id_item_lo,
                kabupaten_kota: itemloall.kabupaten_kota.nama_kabupaten_kota,
                kecamatan: itemloall.kecamatan.nama_kecamatan,
                desa_kelurahan: itemloall.desa_kelurahan.nama_desa_kelurahan,
                jumlah_muatan: itemloall.desa_kelurahan.jumlah_muatan,
                jenis_muatan: itemloall.jenis_muatan,
                titik_bagi: itemloall.titik_bagi
            }));
            const dataitemlo2 = response2.data.map(itemloall2 => ({
                jenis_muatan: itemloall2.jenis_muatan,
                titik_bagi: itemloall2.titik_bagi,
                total_jumlah_muatan: itemloall2.total_jumlah_muatan
            }));
            let total = 0;
            for (let i = 0; i < dataitemlo2.length; i++) {
                total = total + parseInt(dataitemlo2[i].total_jumlah_muatan);
            }
            setLO(response.data.result);
            setItemLO(dataitemlo);
            setTotalLO(total);
            setItemDokumenLO(dataitemlo2);
        } catch (error) {
            console.error('Error fetching', error);
        }
    };

    useEffect(() => {
        fetchLO();
    }, [detailId, idAlokasi]);

    useEffect(() => {
        const fetchArmada = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataarmada = response.data.map(armadaall => ({
                    value: armadaall.id_armada,
                    label: armadaall.nopol_mobil_armada + " - " + armadaall.nama_jenis_mobil
                }));
                setArmadaOption(dataarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmada();
    }, [token, navigate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/driver', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const daradriver = response.data.map(driver => ({
                    value: driver.id_driver,
                    label: driver.nama_driver + " (" + driver.nomor_telpon + ")"
                }));
                setDriverOption(daradriver);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchDriver();
    }, [token, navigate]);

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        const fetchKabupatenOptions = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/kabupaten_kota', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datakabupatenkota = response.data.map(kabupatenkotaall => ({
                    value: kabupatenkotaall.id_kabupaten_kota,
                    label: kabupatenkotaall.nama_kabupaten_kota
                }));
                setKabupatenOption(datakabupatenkota);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchKabupatenOptions();
    }, [token, navigate]);

    const handleKabupatenChange = (selectedOption) => {
        setSelectedKabupaten(selectedOption);
    };

    useEffect(() => {
        const fetchKecamatanOptions = async (kabupatenID) => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/kecamatan/kabupaten/${kabupatenID.value}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datakecamatan = response.data.map(kecamatanall => ({
                    value: kecamatanall.id_kecamatan,
                    label: kecamatanall.nama_kecamatan
                }));
                setKecamatanOption(datakecamatan);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        if (selectedKabupaten) {
            fetchKecamatanOptions(selectedKabupaten);
        }
    }, [selectedKabupaten, token, navigate]);

    const handleKecamatanChange = (selectedOption) => {
        setSelectedKecamatan(selectedOption);
    };

    useEffect(() => {
        setSelectedDriver(initialIdDriverValue);
    }, [driverOption, initialIdDriverValue]);

    useEffect(() => {
        setSelectedArmada(initialIdArmadaValue);
    }, [armadaOption, initialIdArmadaValue]);

    useEffect(() => {
        setSelectedMove(initialIdMoveValue);
    }, [moveOption, initialIdMoveValue]);

    useEffect(() => {
        const fetchDesaOptions = async (kecamatanID) => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/desa_kelurahan/kecamatan/${kecamatanID.value}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datadesa = response.data.map(desaall => ({
                    value: desaall.id_desa_kelurahan,
                    label: desaall.nama_desa_kelurahan,
                    jumlah_muatan: desaall.jumlah_muatan
                }));
                console.log(datadesa);
                setDesaOption(datadesa);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        if (selectedKecamatan) {
            fetchDesaOptions(selectedKecamatan);
        }
    }, [selectedKecamatan, token, navigate]);

    const handleDesaChange = (selectedOption) => {
        setSelectedDesa(selectedOption);
    };

    useEffect(() => {
        setSelectedAlokasi(initialAlokasiValue);
    }, [alokasiOption, initialAlokasiValue]);

    const handleAlokasiChange = async (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const handleJenisMuatanChange = (selectedOption) => {
        setSelectedJenisMuatan(selectedOption);
    };

    const handleDataMuatanChange = (e) => {
        const { name, value } = e.target;
        setFormDataLOSJT({
            ...formDataLOSJT,
            [name]: value
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTambahLOSJT = async () => {
        if (!selectedDesa || !selectedJenisMuatan || formDataLOSJT.titik_bagi == undefined) {
            Swal.fire({
                title: 'Data Loading Order (LO)',
                text: 'Mohon lengkapi data terlebih dahulu',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
        } else {
            const dataToSubmit = {
                ...formDataLOSJT,
                id_lo: detailId,
                id_desa_kelurahan: selectedDesa.value,
                jenis_muatan: selectedJenisMuatan.value
            };
            let link_item_lo = "";
            if (idAlokasi == 4) {
                link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo4";
            } else if (idAlokasi == 5) {
                link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo5";
            } else if (idAlokasi == 6) {
                link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo6";
            } else {
                link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo6";
            }
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                await axios.post(link_item_lo, dataToSubmit,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                Swal.fire({
                    title: 'Data Loading Order (LO)',
                    text: 'Data Berhasil Ditambahkan',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    position: 'center',
                    timerProgressBar: true
                });
                fetchLO();
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        }
    };

    const handleSubmit = async () => {
        const dataToSubmit = ({
            ...formData,
            'id_driver': selectedDriver.value,
            'id_armada': selectedArmada.value,
            'status_lo': selectedStatus.value,
            'id_move': selectedMove.value,
            'tanggal_lo': formData.tanggal_lo.slice(0, 10)
        });
        let link_lo = "";
        if (idAlokasi === 4) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
        } else if (idAlokasi === 5) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
        } else if (idAlokasi === 6) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        } else {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.put(`${link_lo}/${detailId}`,
                dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Loading Order (LO)',
                text: 'Data Berhasil Diperbaharui',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                handleBackClick();
            });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'Session expired, please login again',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                });
            }
        }
    };

    const handleArmadaChange = (selectedOption) => {
        setSelectedArmada(selectedOption);
    };

    const handleDriverChange = (selectedOption) => {
        setSelectedDriver(selectedOption);
    };

    const generatePDF = async () => {
        let jumlah_muatan_total = 0;
        let halaman = 1;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            jumlah_muatan_total = jumlah_muatan_total + parseInt(itemDokumenLO[i].total_jumlah_muatan);
        }
        const tanggaljadi = lo.tanggal_lo.slice(8, 10);
        const bulanjadi = lo.tanggal_lo.slice(5, 7);
        const tahunjadi = lo.tanggal_lo.slice(0, 4);
        const doc = new jsPDF();
        let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
        let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
        let title2 = 'BANTUAN STUNTING';
        let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
        doc.text(title1, 105, 13, { align: 'center' });
        doc.text(title2, 105, 18, { align: 'center' });
        doc.text(title3, 105, 23, { align: 'center' });
        doc.setLineWidth(1);
        doc.line(10, 33, 200, 33);
        doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
        doc.setLineWidth(0.2);
        // Buat Tabel 1
        doc.rect(10, 50, 40, 10);
        doc.rect(50, 50, 55, 10);
        doc.rect(105, 50, 35, 10);
        doc.rect(140, 50, 60, 10);
        doc.rect(10, 60, 40, 10);
        doc.rect(50, 60, 55, 10);
        doc.rect(105, 60, 35, 10);
        doc.rect(140, 60, 60, 10);
        doc.rect(10, 70, 40, 10);
        doc.rect(50, 70, 55, 10);
        doc.rect(105, 70, 35, 10);
        doc.rect(140, 70, 60, 10);
        doc.setFont('helvetica', 'normal');
        doc.text("Tanggal LO", 12, 56);
        doc.text("Nama Driver", 12, 66);
        doc.text("Titik Muat", 12, 76);
        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
        doc.text(": " + lo.nama_driver, 52, 66);
        doc.text(": " + lo.titik_muat, 52, 76);
        doc.text("Nopol Mobil", 107, 56);
        doc.text("Telpon Driver", 107, 66);
        doc.text("Halaman", 107, 76);
        doc.text(": " + lo.nopol_mobil_armada, 142, 56);
        doc.text(": " + lo.nomor_telpon, 142, 66);
        doc.text(": " + String(halaman), 142, 76);
        // Buat table 2
        doc.rect(10, 90, 10, 10);
        doc.rect(20, 90, 25, 10);
        doc.rect(45, 90, 30, 10);
        doc.rect(75, 90, 30, 10);
        doc.rect(105, 90, 95, 10);
        doc.text("No", 15, 96, { align: 'center' });
        doc.text("Item", 32, 96, { align: 'center' });
        doc.text("Jumlah", 60, 96, { align: 'center' });
        doc.text("Keterangan", 90, 96, { align: 'center' });
        doc.text("Titik Bagi", 150, 96, { align: 'center' });
        let ytabello = 90;
        let ykonten = 96;
        let total_total_jumlah_muatan = 0;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            total_total_jumlah_muatan = total_total_jumlah_muatan + parseInt(itemDokumenLO[i].total_jumlah_muatan);
            ykonten = ykonten + 10;
            ytabello = ytabello + 10;
            doc.rect(10, ytabello, 10, 10);
            doc.rect(20, ytabello, 25, 10);
            doc.rect(45, ytabello, 30, 10);
            doc.rect(75, ytabello, 30, 10);
            doc.rect(105, ytabello, 95, 10);
            doc.text(String(i + 1), 15, ykonten, { align: 'center' });
            doc.text(itemDokumenLO[i].jenis_muatan, 22, ykonten, { align: 'left' });
            doc.text(itemDokumenLO[i].total_jumlah_muatan, 60, ykonten, { align: 'center' });
            doc.text("Kondisi Baik", 80, ykonten, { align: 'left' });
            doc.text(itemDokumenLO[i].titik_bagi, 107, ykonten, { align: 'left' })
            if (ytabello > 260) {
                doc.addPage();
                halaman++;
                let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(10);
                let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
                let title2 = 'BANTUAN STUNTING';
                let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                doc.text(title1, 105, 13, { align: 'center' });
                doc.text(title2, 105, 18, { align: 'center' });
                doc.text(title3, 105, 23, { align: 'center' });
                doc.setLineWidth(1);
                doc.line(10, 33, 200, 33);
                doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
                doc.setLineWidth(0.2);
                // Buat Tabel 1
                doc.rect(10, 50, 40, 10);
                doc.rect(50, 50, 55, 10);
                doc.rect(105, 50, 35, 10);
                doc.rect(140, 50, 60, 10);
                doc.rect(10, 60, 40, 10);
                doc.rect(50, 60, 55, 10);
                doc.rect(105, 60, 35, 10);
                doc.rect(140, 60, 60, 10);
                doc.rect(10, 70, 40, 10);
                doc.rect(50, 70, 55, 10);
                doc.rect(105, 70, 35, 10);
                doc.rect(140, 70, 60, 10);
                doc.setFont('helvetica', 'normal');
                doc.text("Tanggal LO", 12, 56);
                doc.text("Nama Driver", 12, 66);
                doc.text("Titik Muat", 12, 76);
                doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                doc.text(": " + lo.nama_driver, 52, 66);
                doc.text(": " + lo.titik_muat, 52, 76);
                doc.text("Nopol Mobil", 107, 56);
                doc.text("Telpon Driver", 107, 66);
                doc.text("Halaman", 107, 76);
                doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                doc.text(": " + lo.nomor_telpon, 142, 66);
                doc.text(": " + String(halaman), 142, 76);
                // Buat table 2
                doc.rect(10, 90, 10, 10);
                doc.rect(20, 90, 25, 10);
                doc.rect(45, 90, 30, 10);
                doc.rect(75, 90, 30, 10);
                doc.rect(105, 90, 95, 10);
                doc.text("No", 15, 96, { align: 'center' });
                doc.text("Item", 32, 96, { align: 'center' });
                doc.text("Jumlah", 60, 96, { align: 'center' });
                doc.text("Keterangan", 90, 96, { align: 'center' });
                doc.text("Titik Bagi", 150, 96, { align: 'center' });
                ytabello = 90;
                ykonten = 96;
            }
        }
        ytabello = ytabello + 10;
        ykonten = ykonten + 10;
        doc.rect(10, ytabello, 10, 10);
        doc.rect(20, ytabello, 25, 10);
        doc.rect(45, ytabello, 30, 10);
        doc.rect(75, ytabello, 30, 10);
        doc.rect(105, ytabello, 95, 10);
        doc.text("Total", 32, ykonten, { align: 'center' });
        doc.text(String(total_total_jumlah_muatan), 60, ykonten, { align: 'center' });

        if (ytabello > 190) {
            doc.addPage();
            halaman++;
            let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
            doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
            let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
            doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
            let title2 = 'BANTUAN STUNTING';
            let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
            doc.text(title1, 105, 13, { align: 'center' });
            doc.text(title2, 105, 18, { align: 'center' });
            doc.text(title3, 105, 23, { align: 'center' });
            doc.setLineWidth(1);
            doc.line(10, 33, 200, 33);
            doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
            doc.setLineWidth(0.2);
            // Buat Tabel 1
            doc.rect(10, 50, 40, 10);
            doc.rect(50, 50, 55, 10);
            doc.rect(105, 50, 35, 10);
            doc.rect(140, 50, 60, 10);
            doc.rect(10, 60, 40, 10);
            doc.rect(50, 60, 55, 10);
            doc.rect(105, 60, 35, 10);
            doc.rect(140, 60, 60, 10);
            doc.rect(10, 70, 40, 10);
            doc.rect(50, 70, 55, 10);
            doc.rect(105, 70, 35, 10);
            doc.rect(140, 70, 60, 10);
            doc.setFont('helvetica', 'normal');
            doc.text("Tanggal LO", 12, 56);
            doc.text("Nama Driver", 12, 66);
            doc.text("Titik Muat", 12, 76);
            doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
            doc.text(": " + lo.nama_driver, 52, 66);
            doc.text(": " + lo.titik_muat, 52, 76);
            doc.text("Nopol Mobil", 107, 56);
            doc.text("Telpon Driver", 107, 66);
            doc.text("Halaman", 107, 76);
            doc.text(": " + lo.nopol_mobil_armada, 142, 56);
            doc.text(": " + lo.nomor_telpon, 142, 66);
            doc.text(": " + String(halaman), 142, 76);
            ytabello = 90;
            ykonten = 91;
            doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykonten);
            doc.text("rician diatas.", 10, ykonten + 5);
            // Buat Tabel 3
            ytabello = ytabello + 15;
            ykonten = ykonten + 19;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("Diserahkan Oleh", 40, ykonten + 1, { align: 'center' });
            doc.text("Diverivikasi Oleh", 105, ykonten + 1, { align: 'center' });
            doc.text("Diterima Oleh", 170, ykonten + 1, { align: 'center' });
            doc.text("PT. BGR Logistics Indonesia", 40, ykonten + 6, { align: 'center' });
            doc.text("PT. Delapan Delapan Logistics", 105, ykonten + 6, { align: 'center' });
            doc.text("Driver", 170, ykonten + 6, { align: 'center' });
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.rect(10, ytabello, 63.3, 35);
            doc.rect(73.3, ytabello, 63.3, 35);
            doc.rect(136.6, ytabello, 63.3, 35);
            ytabello = ytabello + 35;
            ykonten = ykonten + 35;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("" + lo.nama_driver, 170, ykonten + 1, { align: 'center' });
            doc.text("Telp. " + lo.nomor_telpon, 170, ykonten + 6, { align: 'center' });
        } else {
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykonten);
            doc.text("rician diatas.", 10, ykonten + 5);
            // Buat Tabel 3
            ytabello = ytabello + 20;
            ykonten = ykonten + 19;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("Diserahkan Oleh", 40, ykonten + 1, { align: 'center' });
            doc.text("Diverivikasi Oleh", 105, ykonten + 1, { align: 'center' });
            doc.text("Diterima Oleh", 170, ykonten + 1, { align: 'center' });
            doc.text("PT. BGR Logistics Indonesia", 40, ykonten + 6, { align: 'center' });
            doc.text("PT. Delapan Delapan Logistics", 105, ykonten + 6, { align: 'center' });
            doc.text("Driver", 170, ykonten + 6, { align: 'center' });
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.rect(10, ytabello, 63.3, 35);
            doc.rect(73.3, ytabello, 63.3, 35);
            doc.rect(136.6, ytabello, 63.3, 35);
            ytabello = ytabello + 35;
            ykonten = ykonten + 35;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("" + lo.nama_driver, 170, ykonten + 1, { align: 'center' });
            doc.text("Telp. " + lo.nomor_telpon, 170, ykonten + 6, { align: 'center' });
        }
        let no = 1;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            doc.addPage();
            let totalsjt = 0;
            let ytabelsjt = 100;
            let ykontensjt = 106;
            let n = 1;
            halaman = 1;
            totalsjt = 0;
            imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
            doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
            imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
            doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            title1 = 'SURAT JALAN TRANSPORTER (SJT)';
            title2 = 'BANTUAN STUNTING';
            title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
            doc.text(title1, 105, 13, { align: 'center' });
            doc.text(title2, 105, 18, { align: 'center' });
            doc.text(title3, 105, 23, { align: 'center' });
            doc.setLineWidth(1);
            doc.line(10, 33, 200, 33);
            doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
            doc.setLineWidth(0.2);
            // Buat Tabel 1
            doc.rect(10, 50, 40, 10);
            doc.rect(50, 50, 55, 10);
            doc.rect(105, 50, 35, 10);
            doc.rect(140, 50, 60, 10);
            doc.rect(10, 60, 40, 10);
            doc.rect(50, 60, 55, 10);
            doc.rect(105, 60, 35, 10);
            doc.rect(140, 60, 60, 10);
            doc.rect(10, 70, 40, 10);
            doc.rect(50, 70, 55, 10);
            doc.rect(105, 70, 35, 10);
            doc.rect(140, 70, 60, 10);
            doc.rect(10, 80, 40, 10);
            doc.rect(50, 80, 135, 10);
            doc.rect(185, 80, 15, 10);
            doc.setFont('helvetica', 'normal');
            doc.text("Tanggal LO", 12, 56);
            doc.text("Nama Driver", 12, 66);
            doc.text("Titik Muat", 12, 76);
            doc.text("Titik Bagi", 12, 86);
            doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
            doc.text(": " + lo.nama_driver, 52, 66);
            doc.text(": " + lo.titik_muat, 52, 76);
            doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
            doc.text("Nopol Mobil", 107, 56);
            doc.text("Telpon Driver", 107, 66);
            doc.text("Jenis Muatan", 107, 76);
            doc.text(String(halaman++), 191, 86);
            doc.text(": " + lo.nopol_mobil_armada, 142, 56);
            doc.text(": " + lo.nomor_telpon, 142, 66);
            doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
            // Buat table 2
            doc.rect(10, 100, 10, 10);
            doc.rect(20, 100, 55, 10);
            doc.rect(75, 100, 55, 10);
            doc.rect(130, 100, 55, 10);
            doc.rect(185, 100, 15, 10);
            doc.text("No", 15, 106, { align: 'center' });
            doc.text("Kabupaten/Kota", 22, 106, { align: 'left' });
            doc.text("Kecamatan", 77, 106, { align: 'left' });
            doc.text("Desa/Kelurahan", 132, 106, { align: 'left' });
            doc.text("Jumlah", 192, 106, { align: 'center' });
            ytabelsjt = 100;
            ykontensjt = 106;
            for (let y = 0; y < itemLO.length; y++) {
                if (itemLO[y].titik_bagi === itemDokumenLO[i].titik_bagi) {
                    ytabelsjt = ytabelsjt + 10;
                    ykontensjt = ykontensjt + 10;
                    if (ytabelsjt > 260) {
                        doc.addPage();
                        imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                        imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                        doc.setFont('helvetica', 'bold');
                        doc.setFontSize(10);
                        title1 = 'SURAT JALAN TRANSPORTER (SJT)';
                        title2 = 'BANTUAN STUNTING';
                        title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                        doc.text(title1, 105, 13, { align: 'center' });
                        doc.text(title2, 105, 18, { align: 'center' });
                        doc.text(title3, 105, 23, { align: 'center' });
                        doc.setLineWidth(1);
                        doc.line(10, 33, 200, 33);
                        doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
                        doc.setLineWidth(0.2);
                        // Buat Tabel 1
                        doc.rect(10, 50, 40, 10);
                        doc.rect(50, 50, 55, 10);
                        doc.rect(105, 50, 35, 10);
                        doc.rect(140, 50, 60, 10);
                        doc.rect(10, 60, 40, 10);
                        doc.rect(50, 60, 55, 10);
                        doc.rect(105, 60, 35, 10);
                        doc.rect(140, 60, 60, 10);
                        doc.rect(10, 70, 40, 10);
                        doc.rect(50, 70, 55, 10);
                        doc.rect(105, 70, 35, 10);
                        doc.rect(140, 70, 60, 10);
                        doc.rect(10, 80, 40, 10);
                        doc.rect(50, 80, 135, 10);
                        doc.rect(185, 80, 15, 10);
                        doc.setFont('helvetica', 'normal');
                        doc.text("Tanggal LO", 12, 56);
                        doc.text("Nama Driver", 12, 66);
                        doc.text("Titik Muat", 12, 76);
                        doc.text("Titik Bagi", 12, 86);
                        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                        doc.text(": " + lo.nama_driver, 52, 66);
                        doc.text(": " + lo.titik_muat, 52, 76);
                        doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
                        doc.text("Nopol Mobil", 107, 56);
                        doc.text("Telpon Driver", 107, 66);
                        doc.text("Jenis Muatan", 107, 76);
                        doc.text(String(halaman++), 191, 86);
                        doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                        doc.text(": " + lo.nomor_telpon, 142, 66);
                        doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
                        // Buat table 2
                        doc.rect(10, 100, 10, 10);
                        doc.rect(20, 100, 55, 10);
                        doc.rect(75, 100, 55, 10);
                        doc.rect(130, 100, 55, 10);
                        doc.rect(185, 100, 15, 10);
                        doc.text("No", 15, 106, { align: 'center' });
                        doc.text("Kabupaten/Kota", 22, 106, { align: 'left' });
                        doc.text("Kecamatan", 77, 106, { align: 'left' });
                        doc.text("Desa/Kelurahan", 132, 106, { align: 'left' });
                        doc.text("Jumlah", 192, 106, { align: 'center' });
                        ytabelsjt = 100;
                        ykontensjt = 106;
                    } else {
                        doc.rect(10, ytabelsjt, 10, 10);
                        doc.rect(20, ytabelsjt, 55, 10);
                        doc.rect(75, ytabelsjt, 55, 10);
                        doc.rect(130, ytabelsjt, 55, 10);
                        doc.rect(185, ytabelsjt, 15, 10);
                        doc.text(String(n++), 15, ykontensjt, { align: 'center' });
                        doc.text(itemLO[y].kabupaten_kota, 22, ykontensjt, { align: 'left' });
                        doc.text(itemLO[y].kecamatan, 77, ykontensjt, { align: 'left' });
                        doc.text(itemLO[y].desa_kelurahan, 132, ykontensjt, { align: 'left' });
                        doc.text(String(itemLO[y].jumlah_muatan), 192, ykontensjt, { align: 'center' });
                    }
                    totalsjt = totalsjt + itemLO[y].jumlah_muatan;
                }
            }
            ytabelsjt = ytabelsjt + 10;
            ykontensjt = ykontensjt + 10;
            doc.rect(10, ytabelsjt, 175, 10);
            doc.rect(185, ytabelsjt, 15, 10);
            doc.text("Total", 100, ykontensjt, { align: 'center' });
            doc.text(String(totalsjt), 192, ykontensjt, { align: 'center' });
            if (ytabelsjt > 190) {
                doc.addPage();
                imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(10);
                title1 = 'SURAT JALAN TRANSPORTER (SJT)';
                title2 = 'BANTUAN STUNTING';
                title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                doc.text(title1, 105, 13, { align: 'center' });
                doc.text(title2, 105, 18, { align: 'center' });
                doc.text(title3, 105, 23, { align: 'center' });
                doc.setLineWidth(1);
                doc.line(10, 33, 200, 33);
                doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
                doc.setLineWidth(0.2);
                // Buat Tabel 1
                doc.rect(10, 50, 40, 10);
                doc.rect(50, 50, 55, 10);
                doc.rect(105, 50, 35, 10);
                doc.rect(140, 50, 60, 10);
                doc.rect(10, 60, 40, 10);
                doc.rect(50, 60, 55, 10);
                doc.rect(105, 60, 35, 10);
                doc.rect(140, 60, 60, 10);
                doc.rect(10, 70, 40, 10);
                doc.rect(50, 70, 55, 10);
                doc.rect(105, 70, 35, 10);
                doc.rect(140, 70, 60, 10);
                doc.rect(10, 80, 40, 10);
                doc.rect(50, 80, 135, 10);
                doc.rect(185, 80, 15, 10);
                doc.setFont('helvetica', 'normal');
                doc.text("Tanggal LO", 12, 56);
                doc.text("Nama Driver", 12, 66);
                doc.text("Titik Muat", 12, 76);
                doc.text("Titik Bagi", 12, 86);
                doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                doc.text(": " + lo.nama_driver, 52, 66);
                doc.text(": " + lo.titik_muat, 52, 76);
                doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
                doc.text("Nopol Mobil", 107, 56);
                doc.text("Telpon Driver", 107, 66);
                doc.text("Jenis Muatan", 107, 76);
                doc.text(String(halaman++), 191, 86);
                doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                doc.text(": " + lo.nomor_telpon, 142, 66);
                doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
                ytabelsjt = 100;
                ykontensjt = 106;
                doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykontensjt);
                doc.text("rician diatas.", 10, ykontensjt + 5);
                // Buat Tabel 3
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 14;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("Diserahkan Oleh", 40, ykontensjt + 1, { align: 'center' });
                doc.text("Ditugaskan Kepada", 105, ykontensjt + 1, { align: 'center' });
                doc.text("Diterima Oleh", 170, ykontensjt + 1, { align: 'center' });
                doc.text("PT. Delapan Delapan Logistics", 40, ykontensjt + 6, { align: 'center' });
                doc.text("Driver", 105, ykontensjt + 6, { align: 'center' });
                doc.text("Petugas Titik Bagi", 170, ykontensjt + 6, { align: 'center' });
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.rect(10, ytabelsjt, 63.3, 35);
                doc.rect(73.3, ytabelsjt, 63.3, 35);
                doc.rect(136.6, ytabelsjt, 63.3, 35);
                ytabelsjt = ytabelsjt + 35;
                ykontensjt = ykontensjt + 35;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("" + lo.nama_driver, 105, ykontensjt + 1, { align: 'center' });
                doc.text("Telp. " + lo.nomor_telpon, 105, ykontensjt + 6, { align: 'center' });
            } else {
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykontensjt);
                doc.text("rician diatas.", 10, ykontensjt + 5);
                // Buat Tabel 3
                ytabelsjt = ytabelsjt + 20;
                ykontensjt = ykontensjt + 19;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("Diserahkan Oleh", 40, ykontensjt + 1, { align: 'center' });
                doc.text("Ditugaskan Kepada", 105, ykontensjt + 1, { align: 'center' });
                doc.text("Diterima Oleh", 170, ykontensjt + 1, { align: 'center' });
                doc.text("PT. Delapan Delapan Logistics", 40, ykontensjt + 6, { align: 'center' });
                doc.text("Driver", 105, ykontensjt + 6, { align: 'center' });
                doc.text("Petugas Titik Bagi", 170, ykontensjt + 6, { align: 'center' });
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.rect(10, ytabelsjt, 63.3, 35);
                doc.rect(73.3, ytabelsjt, 63.3, 35);
                doc.rect(136.6, ytabelsjt, 63.3, 35);
                ytabelsjt = ytabelsjt + 35;
                ykontensjt = ykontensjt + 35;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("" + lo.nama_driver, 105, ykontensjt + 1, { align: 'center' });
                doc.text("Telp. " + lo.nomor_telpon, 105, ykontensjt + 6, { align: 'center' });
            }
            no++;
        }
        doc.save(lo.nomor_lo);
    };

    const handleDeleteItemLO = async (iditemlo) => {
        let link_item_lo = "";
        if (idAlokasi == 4) {
            link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo4";
        } else if (idAlokasi == 5) {
            link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo5";
        } else if (idAlokasi == 6) {
            link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo6";
        } else {
            link_item_lo = "https://api.midstunting.delapandelapanlogistics.com/api/item_lo6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.delete(link_item_lo + '/' + iditemlo,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Loading Order (LO)',
                text: 'Data Berhasil Dihapus',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
            fetchLO();
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Detail Loading Order (LO)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Loading Order (LO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                        <Select
                            id="id_alokasi"
                            name="id_alokasi"
                            value={selectedAlokasi}
                            onChange={handleAlokasiChange}
                            options={alokasiOption}
                            placeholder="PILIH ALOKASI"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="nomor_lo" className="form-label">Nomor LO</label>
                        <input className="form-control text-uppercase" type="text" id="nomor_lo" name='nomor_lo' placeholder="NOMOR LO" ref={inputRef} defaultValue={lo?.nomor_lo || ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_move" className="form-label">NOMOR MOVE</label>
                        <Select
                            id="id_move"
                            name="id_move"
                            value={selectedMove}
                            onChange={handleMoveChange}
                            options={moveOption}
                            placeholder="PILIH NOMOR MOVE"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="tanggal_lo" className="form-label">Tanggal LO</label>
                        <input className="form-control text-uppercase" type="date" id="tanggal_lo" name='tanggal_lo' placeholder="" ref={inputRef} defaultValue={lo ? lo.tanggal_lo.slice(0, 10) : ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="titik_muat" className="form-label">Titik Muat</label>
                        <input className="form-control" type="text" id="titik_muat" name='titik_muat' placeholder="TITIK MUAT" ref={inputRef} defaultValue={lo?.titik_muat || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_armada" className="form-label">ARMADA</label>
                        <Select
                            id="id_armada"
                            name="id_armada"
                            value={selectedArmada}
                            onChange={handleArmadaChange}
                            options={armadaOption}
                            placeholder="PILIH ARMADA"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_driver" className="form-label">DRIVER</label>
                        <Select
                            id="id_driver"
                            name="id_driver"
                            value={selectedDriver}
                            onChange={handleDriverChange}
                            options={driverOption}
                            placeholder="PILIH DRIVER"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label className="form-label">STATUS</label>
                        <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            options={statusLO}
                            placeholder="PILIH STATUS"
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="" className="form-label">Proses</label>
                        <button type="button" className="btn btn-primary w-100" onClick={() => handleSubmit()} >SIMPAN PERUBAHAN</button>
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="" className="form-label">Proses</label>
                        <button type="button" className="btn btn-primary w-100" onClick={() => generatePDF()} >DOWNLOAD</button>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            Tambah Detail LO SJT
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="id_kabupaten_kota" className="form-label">KABUPATEN/KOTA</label>
                <Select
                    id="id_kabupaten_kota"
                    name="id_kabupaten_kota"
                    value={selectedKabupaten}
                    onChange={handleKabupatenChange}
                    options={kabupatenOption}
                    placeholder="PILIH KABUPATEN/KOTA"
                />
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="id_kecamatan" className="form-label">KECAMATAN</label>
                <Select
                    id="id_kecamatan"
                    name="id_kecamatan"
                    value={selectedKecamatan}
                    onChange={handleKecamatanChange}
                    options={kecamatanOption}
                    placeholder="PILIH KECAMATAN"
                />
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="id_desa_kelurahan" className="form-label">DESA/KELURAHAN</label>
                <Select
                    id="id_desa_kelurahan"
                    name="id_desa_kelurahan"
                    value={selectedDesa}
                    onChange={handleDesaChange}
                    options={desaOption}
                    placeholder="PILIH DESA/KELURAHAN"
                />
            </div>
            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                <label htmlFor="jenis_muatan" className="form-label">JENIS MUATAN</label>
                <Select
                    id="jenis_muatan"
                    name="jenis_muatan"
                    value={selectedJenisMuatan}
                    onChange={handleJenisMuatanChange}
                    options={jenisMuatanOption}
                    placeholder="PILIH JENIS MUATAN"
                    required
                />
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
                <label htmlFor="jumlah_krs" className="form-label">JUMLAH KRS</label>
                <input className="form-control text-uppercase" type="number" id="jumlah_krs" name='jumlah_krs' placeholder="0" ref={inputRef} defaultValue={selectedDesa?.jumlah_muatan || ""} readOnly />
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
                <label htmlFor="titik_bagi" className="form-label">TITIK BAGI</label>
                <input className="form-control text-uppercase" type="text" id="titik_bagi" name='titik_bagi' placeholder="" onChange={handleDataMuatanChange} required />
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
                <label htmlFor="" className="form-label">Proses</label>
                <button className="btn btn-primary w-100" onClick={() => handleTambahLOSJT()} >TAMBAHKAN</button>
            </div>
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            List Titik Drop
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-4 mb-md-0">
                <div className='table-responsive text-nowrap'>
                    <table className="table" style={{ fontSize: "13px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "5px" }} >No</th>
                                <th>KABUPATEN/KOTA</th>
                                <th>KECAMATAN</th>
                                <th>DESA/KELURAHAN</th>
                                <th className='text-center' >MUATAN</th>
                                <th>TITIK BAGI</th>
                                <th style={{ width: "5px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemLO.map((itemlo, index) => (
                                <tr key={index}>
                                    <td>{itemlo.nomor}</td>
                                    <td>{itemlo.kabupaten_kota}</td>
                                    <td>{itemlo.kecamatan}</td>
                                    <td>{itemlo.desa_kelurahan}</td>
                                    <td className='text-center' >{itemlo.jumlah_muatan} {itemlo.jenis_muatan}</td>
                                    <td>{itemlo.titik_bagi}</td>
                                    <td>
                                        <button className="btn btn-link text-danger w-100 text-start" type="button" id="button-addon2" onClick={() => handleDeleteItemLO(itemlo.id_item_lo)}>  <i className="tf-icons bx bx-minus-circle me-2"></i> Hapus</button>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4} className='text-center fw-bold' >TOTAL</td>
                                <td className='text-center fw-bold' >{totalLO} {itemLO[0]?.jenis_muatan || ''} </td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
};

DetailPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    detailId: PropTypes.number.isRequired,
    idAlokasi: PropTypes.number.isRequired,
    statusLOInit: PropTypes.string.isRequired,
    detailIdDriver: PropTypes.number.isRequired,
    detailIdArmada: PropTypes.number.isRequired,
    detailIdMove: PropTypes.number.isRequired
};

export default DetailPage;