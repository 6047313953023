import React, { useState, useEffect } from 'react';
import AddPage from './addPage';
import DetailPage from './detailPage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const IndexVendorPage = () => {
    const token = localStorage.getItem('token');
    const [currentView, setCurrentView] = useState('index');
    const [detailId, setDetailId] = useState(null);
    const navigate = useNavigate();
    const [vendorArmada, setVendorArmada] = useState([]);

    const fetchVendorArmadaAll = async () => {
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const options = response.data.map(vendor => ({
                id_vendor: vendor.id_vendor,
                nama_vendor: vendor.nama_vendor,
                alamat_vendor: vendor.alamat_vendor,
                penanggungjawab_vendor: vendor.penanggungjawab_vendor,
                telpon_vendor: vendor.telpon_vendor,
                jumlah_armada: vendor.jumlah_armada,
                status_vendor: vendor.status_vendor
            }));
            setVendorArmada(options);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'Session expired, please login again',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                });
            }
        }
    };

    useEffect(() => {
        fetchVendorArmadaAll();
    }, []);

    const handlePageChange = (page, id = null) => {
        setCurrentView(page);
        if (id !== null) {
            setDetailId(id);
        }
    };

    const handleBackClick = () => {
        setCurrentView('index');
        fetchVendorArmadaAll();
    };

    return (
        <div>
            {currentView === 'index' && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="divider text-start">
                                <div className="divider-text">
                                    <span className="menu-header-text fs-6 fw-bold">Data Penyedia Armada</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="">
                            Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('add')}>disini</button> untuk menambahkan data Penyedia Armada.
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="row">
                            <div className="col-md-12 mb-4 mb-md-0">
                                <div className="accordion mt-3" id="accordion_vendor_armada">
                                    {vendorArmada.map(va => (
                                        <div key={va.id_vendor} className="card accordion-item">
                                            <h2 className="accordion-header px-2" id={`heading${va.id_vendor}`}>
                                                <button
                                                    type="button"
                                                    className={`accordion-button accordion-button-primary collapsed ${(va.status_vendor == "TIDAK TERSEDIA") ? 'text-danger' : 'text-primary'}`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordion${va.id_vendor}`}
                                                    aria-expanded="false"
                                                    aria-controls={`accordion${va.id_vendor}`}
                                                >
                                                    {va.nama_vendor} | ARMADA {va.jumlah_armada} | {va.status_vendor}
                                                </button>
                                            </h2>
                                            <div id={`accordion${va.id_vendor}`} className="accordion-collapse collapse" data-bs-parent="#accordion_vendor_armada">
                                                <div className="accordion-body" style={{ marginTop: "-15px" }} >
                                                    <div className="px-2">
                                                        <hr />
                                                        <div className="col-md-12 col-sm-12 mt-0 mt-md-3">
                                                            <p style={{ marginBottom: "2px" }}>
                                                                {va.nama_vendor}
                                                            </p>
                                                            <p style={{ marginBottom: "2px" }}>
                                                                PENANGGUNG JAWAB {va.penanggungjawab_vendor}
                                                            </p>
                                                            <p style={{ marginBottom: "2px" }}>
                                                                TELPON PENANGGUNG JAWAB {va.telpon_vendor}
                                                            </p>
                                                            <p style={{ marginBottom: "2px" }}>
                                                                {va.jumlah_armada} ARMADA
                                                            </p>
                                                            <button className="btn btn-link p-0 mt-3" onClick={() => handlePageChange("detail", va.id_vendor)}>
                                                                <i className="tf-icons bx bx-edit me-2"></i> DETAIL
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentView === 'add' && <AddPage handlePageChange={handlePageChange} handleBackClick={handleBackClick} />}
            {currentView === 'detail' && <DetailPage handlePageChange={handlePageChange} detailId={detailId} handleBackClick={handleBackClick} />}
        </div>
    );
};

export default IndexVendorPage;