import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import menuData from '../data/menuData.json';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Fungsi untuk memfilter menu berdasarkan peran
const filterMenuByRole = (menuData, role) => {
    return menuData.map(section => ({
        ...section,
        items: section.items.filter(item => item.roles.includes(role) && item.available)
    })).filter(section => section.items.length > 0);
};


// Fungsi logout
const handleLogout = () => {
    // Hapus token dari localStorage atau sessionStorage
    localStorage.removeItem('token');
    // Redirect ke halaman login
    // navigate('/login');
};

// Fungsi untuk menangani klik item menu
const handleMenuClick = (item) => {
    if (item.action === 'logout') {
        // Panggil fungsi logout jika action adalah logout
        handleLogout();
    }
};

const MenuItem = ({ item }) => {
    const location = useLocation();
    const isActive = location.pathname === item.link;
    const hasSubmenu = item.submenu && item.submenu.length > 0;
    const isSubmenuActive = hasSubmenu && item.submenu.some(subitem => location.pathname === subitem.link);

    return (
        <li onClick={() => handleMenuClick(item)} className={`menu-item ${isActive || isSubmenuActive ? 'active' : ''} ${hasSubmenu && isSubmenuActive ? 'open' : ''}`}>
            {item.link && (
                <NavLink
                    aria-label={`Navigate to ${item.text} ${!item.available ? 'Pro' : ''}`}
                    to={item.link}
                    className={`menu-link ${item.submenu ? 'menu-toggle' : ''}`}
                    target={item.link.includes('http') ? '_blank' : undefined}
                >
                    <i className={`menu-icon tf-icons ${item.icon}`}></i>
                    <div>{item.text}</div> {item.available === false && (
                        <div className="badge bg-label-primary fs-tiny rounded-pill ms-auto">Pro</div>
                    )}
                </NavLink>
            )}
            {item.submenu && (
                <ul className="menu-sub">{item.submenu.map((subitem, index) => (
                    <MenuItem key={index} item={subitem} />
                ))}</ul>
            )}
        </li>
    );
};

MenuItem.propTypes = {
    item: PropTypes.shape({
        link: PropTypes.string,
        submenu: PropTypes.arrayOf(PropTypes.object),
        icon: PropTypes.string,
        text: PropTypes.string,
        available: PropTypes.bool
    }).isRequired
};

const Sidebar = () => {
    // Ambil role dari localStorage
    const userRole = localStorage.getItem('role') || '';

    // Filter menu berdasarkan role pengguna
    const filteredMenuData = filterMenuByRole(menuData, userRole);

    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
                <span className="app-brand-logo demo">
                    <img src="/assets/img/logos/logo.png" style={{ width: '200px' }} alt="sneat-logo" aria-label='Sneat logo image' />
                </span>
            </div>
            <div className="menu-inner-shadow"></div>
            <ul className="menu-inner py-1">
                {filteredMenuData.map((section, index) => (
                    <React.Fragment key={index}>
                        {section.header && (
                            <li className="menu-header small text-uppercase">
                                <span className="menu-header-text">{section.header}</span>
                            </li>
                        )}
                        {section.items.map((item, itemIndex) => (
                            <MenuItem key={itemIndex} item={item} />
                        ))}
                    </React.Fragment>
                ))}
            </ul>
        </aside>
    );
};

Sidebar.propTypes = {
    userRole: PropTypes.string.isRequired
};

export default Sidebar;
