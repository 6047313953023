import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";


const DetailPage = ({ handlePageChange, detailId, idAlokasi, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const initialAlokasiValue = alokasiOption.find(option => option.value === idAlokasi) || null;
    const [lo, setLO] = useState({
        id_lo: "",
        id_kantor: "",
        id_user: "",
        id_driver: "",
        id_armada: "",
        nama_driver: "",
        nomor_telpon: "",
        nopol_mobil_armada: "",
        nama_jenis_mobil: "",
        tanggal_lo: "",
        titik_muat: "",
        status_lo: "",
        kode_kantor: "",
        nomor_lo: ""
    });
    const [itemLO, setItemLO] = useState([]);
    const [itemDokumenLO, setItemDokumenLO] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(initialAlokasiValue);

    const fetchLO = async () => {
        if (!token) {
            Swal.fire({
                title: 'Session Expired',
                text: 'Please login to continue',
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate('/login');
            });
            return;
        }
        try {
            let nomor = 1;
            let link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
            let link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo4/bahandokumen";
            if (idAlokasi === 4) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo4/bahandokumen";
            } else if (idAlokasi === 5) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo5/bahandokumen";
            } else if (idAlokasi === 6) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo6/bahandokumen";
            } else {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
                link_lo_2 = "https://api.midstunting.delapandelapanlogistics.com/api/lo6/bahandokumen";
            }
            const response = await axios.get(`${link_lo}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const response2 = await axios.get(`${link_lo_2}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            let bahandataitemlo = response.data.result.items.sort((a, b) => {
                if (a.titik_bagi < b.titik_bagi) return -1;
                if (a.titik_bagi > b.titik_bagi) return 1;
                return 0;
            });
            const dataitemlo = bahandataitemlo.map(itemloall => ({
                nomor: nomor++,
                id_item_lo: itemloall.id_item_lo,
                kabupaten_kota: itemloall.kabupaten_kota.nama_kabupaten_kota,
                kecamatan: itemloall.kecamatan.nama_kecamatan,
                desa_kelurahan: itemloall.desa_kelurahan.nama_desa_kelurahan,
                jumlah_muatan: itemloall.desa_kelurahan.jumlah_muatan,
                jenis_muatan: itemloall.jenis_muatan,
                titik_bagi: itemloall.titik_bagi
            }));
            console.log(dataitemlo);
            const dataitemlo2 = response2.data.map(itemloall2 => ({
                jenis_muatan: itemloall2.jenis_muatan,
                titik_bagi: itemloall2.titik_bagi,
                total_jumlah_muatan: itemloall2.total_jumlah_muatan
            }));
            setLO(response.data.result);
            setItemLO(dataitemlo);
            setItemDokumenLO(dataitemlo2);
        } catch (error) {
            console.error('Error fetching', error);
        }
    };

    useEffect(() => {
        fetchLO();
    }, [detailId, idAlokasi]);

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        setSelectedAlokasi(initialAlokasiValue);
    }, [alokasiOption, initialAlokasiValue]);


    const handleAlokasiChange = (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const generatePDF = async () => {
        let jumlah_muatan_total = 0;
        let halaman = 1;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            jumlah_muatan_total = jumlah_muatan_total + parseInt(itemDokumenLO[i].total_jumlah_muatan);
        }
        const tanggaljadi = lo.tanggal_lo.slice(8, 10);
        const bulanjadi = lo.tanggal_lo.slice(5, 7);
        const tahunjadi = lo.tanggal_lo.slice(0, 4);
        const doc = new jsPDF();
        let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
        let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
        let title2 = 'BANTUAN STUNTING';
        let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
        doc.text(title1, 105, 13, { align: 'center' });
        doc.text(title2, 105, 18, { align: 'center' });
        doc.text(title3, 105, 23, { align: 'center' });
        doc.setLineWidth(1);
        doc.line(10, 33, 200, 33);
        doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
        doc.setLineWidth(0.2);
        // Buat Tabel 1
        doc.rect(10, 50, 40, 10);
        doc.rect(50, 50, 55, 10);
        doc.rect(105, 50, 35, 10);
        doc.rect(140, 50, 60, 10);
        doc.rect(10, 60, 40, 10);
        doc.rect(50, 60, 55, 10);
        doc.rect(105, 60, 35, 10);
        doc.rect(140, 60, 60, 10);
        doc.rect(10, 70, 40, 10);
        doc.rect(50, 70, 55, 10);
        doc.rect(105, 70, 35, 10);
        doc.rect(140, 70, 60, 10);
        doc.setFont('helvetica', 'normal');
        doc.text("Tanggal LO", 12, 56);
        doc.text("Nama Driver", 12, 66);
        doc.text("Titik Muat", 12, 76);
        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
        doc.text(": " + lo.nama_driver, 52, 66);
        doc.text(": " + lo.titik_muat, 52, 76);
        doc.text("Nopol Mobil", 107, 56);
        doc.text("Telpon Driver", 107, 66);
        doc.text("Halaman", 107, 76);
        doc.text(": " + lo.nopol_mobil_armada, 142, 56);
        doc.text(": " + lo.nomor_telpon, 142, 66);
        doc.text(": " + String(halaman), 142, 76);
        // Buat table 2
        doc.rect(10, 90, 10, 10);
        doc.rect(20, 90, 25, 10);
        doc.rect(45, 90, 30, 10);
        doc.rect(75, 90, 30, 10);
        doc.rect(105, 90, 95, 10);
        doc.text("No", 15, 96, { align: 'center' });
        doc.text("Item", 32, 96, { align: 'center' });
        doc.text("Jumlah", 60, 96, { align: 'center' });
        doc.text("Keterangan", 90, 96, { align: 'center' });
        doc.text("Titik Bagi", 150, 96, { align: 'center' });
        let ytabello = 90;
        let ykonten = 96;
        let total_total_jumlah_muatan = 0;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            total_total_jumlah_muatan = total_total_jumlah_muatan + parseInt(itemDokumenLO[i].total_jumlah_muatan);
            ykonten = ykonten + 10;
            ytabello = ytabello + 10;
            doc.rect(10, ytabello, 10, 10);
            doc.rect(20, ytabello, 25, 10);
            doc.rect(45, ytabello, 30, 10);
            doc.rect(75, ytabello, 30, 10);
            doc.rect(105, ytabello, 95, 10);
            doc.text(String(i + 1), 15, ykonten, { align: 'center' });
            doc.text(itemDokumenLO[i].jenis_muatan, 22, ykonten, { align: 'left' });
            doc.text(itemDokumenLO[i].total_jumlah_muatan, 60, ykonten, { align: 'center' });
            doc.text("Kondisi Baik", 80, ykonten, { align: 'left' });
            doc.text(itemDokumenLO[i].titik_bagi, 107, ykonten, { align: 'left' })
            if (ytabello > 260) {
                doc.addPage();
                halaman++;
                let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(10);
                let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
                let title2 = 'BANTUAN STUNTING';
                let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                doc.text(title1, 105, 13, { align: 'center' });
                doc.text(title2, 105, 18, { align: 'center' });
                doc.text(title3, 105, 23, { align: 'center' });
                doc.setLineWidth(1);
                doc.line(10, 33, 200, 33);
                doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
                doc.setLineWidth(0.2);
                // Buat Tabel 1
                doc.rect(10, 50, 40, 10);
                doc.rect(50, 50, 55, 10);
                doc.rect(105, 50, 35, 10);
                doc.rect(140, 50, 60, 10);
                doc.rect(10, 60, 40, 10);
                doc.rect(50, 60, 55, 10);
                doc.rect(105, 60, 35, 10);
                doc.rect(140, 60, 60, 10);
                doc.rect(10, 70, 40, 10);
                doc.rect(50, 70, 55, 10);
                doc.rect(105, 70, 35, 10);
                doc.rect(140, 70, 60, 10);
                doc.setFont('helvetica', 'normal');
                doc.text("Tanggal LO", 12, 56);
                doc.text("Nama Driver", 12, 66);
                doc.text("Titik Muat", 12, 76);
                doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                doc.text(": " + lo.nama_driver, 52, 66);
                doc.text(": " + lo.titik_muat, 52, 76);
                doc.text("Nopol Mobil", 107, 56);
                doc.text("Telpon Driver", 107, 66);
                doc.text("Halaman", 107, 76);
                doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                doc.text(": " + lo.nomor_telpon, 142, 66);
                doc.text(": " + String(halaman), 142, 76);
                // Buat table 2
                doc.rect(10, 90, 10, 10);
                doc.rect(20, 90, 25, 10);
                doc.rect(45, 90, 30, 10);
                doc.rect(75, 90, 30, 10);
                doc.rect(105, 90, 95, 10);
                doc.text("No", 15, 96, { align: 'center' });
                doc.text("Item", 32, 96, { align: 'center' });
                doc.text("Jumlah", 60, 96, { align: 'center' });
                doc.text("Keterangan", 90, 96, { align: 'center' });
                doc.text("Titik Bagi", 150, 96, { align: 'center' });
                ytabello = 90;
                ykonten = 96;
            }
        }
        ytabello = ytabello + 10;
        ykonten = ykonten + 10;
        doc.rect(10, ytabello, 10, 10);
        doc.rect(20, ytabello, 25, 10);
        doc.rect(45, ytabello, 30, 10);
        doc.rect(75, ytabello, 30, 10);
        doc.rect(105, ytabello, 95, 10);
        doc.text("Total", 32, ykonten, { align: 'center' });
        doc.text(String(total_total_jumlah_muatan), 60, ykonten, { align: 'center' });

        if (ytabello > 190) {
            doc.addPage();
            halaman++;
            let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
            doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
            let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
            doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
            let title2 = 'BANTUAN STUNTING';
            let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
            doc.text(title1, 105, 13, { align: 'center' });
            doc.text(title2, 105, 18, { align: 'center' });
            doc.text(title3, 105, 23, { align: 'center' });
            doc.setLineWidth(1);
            doc.line(10, 33, 200, 33);
            doc.text("NOMOR LO : " + lo.nomor_lo, 105, 43, { align: 'center' });
            doc.setLineWidth(0.2);
            // Buat Tabel 1
            doc.rect(10, 50, 40, 10);
            doc.rect(50, 50, 55, 10);
            doc.rect(105, 50, 35, 10);
            doc.rect(140, 50, 60, 10);
            doc.rect(10, 60, 40, 10);
            doc.rect(50, 60, 55, 10);
            doc.rect(105, 60, 35, 10);
            doc.rect(140, 60, 60, 10);
            doc.rect(10, 70, 40, 10);
            doc.rect(50, 70, 55, 10);
            doc.rect(105, 70, 35, 10);
            doc.rect(140, 70, 60, 10);
            doc.setFont('helvetica', 'normal');
            doc.text("Tanggal LO", 12, 56);
            doc.text("Nama Driver", 12, 66);
            doc.text("Titik Muat", 12, 76);
            doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
            doc.text(": " + lo.nama_driver, 52, 66);
            doc.text(": " + lo.titik_muat, 52, 76);
            doc.text("Nopol Mobil", 107, 56);
            doc.text("Telpon Driver", 107, 66);
            doc.text("Halaman", 107, 76);
            doc.text(": " + lo.nopol_mobil_armada, 142, 56);
            doc.text(": " + lo.nomor_telpon, 142, 66);
            doc.text(": " + String(halaman), 142, 76);
            ytabello = 90;
            ykonten = 91;
            doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykonten);
            doc.text("rician diatas.", 10, ykonten + 5);
            // Buat Tabel 3
            ytabello = ytabello + 15;
            ykonten = ykonten + 19;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("Diserahkan Oleh", 40, ykonten + 1, { align: 'center' });
            doc.text("Diverivikasi Oleh", 105, ykonten + 1, { align: 'center' });
            doc.text("Diterima Oleh", 170, ykonten + 1, { align: 'center' });
            doc.text("PT. BGR Logistics Indonesia", 40, ykonten + 6, { align: 'center' });
            doc.text("PT. Delapan Delapan Logistics", 105, ykonten + 6, { align: 'center' });
            doc.text("Driver", 170, ykonten + 6, { align: 'center' });
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.rect(10, ytabello, 63.3, 35);
            doc.rect(73.3, ytabello, 63.3, 35);
            doc.rect(136.6, ytabello, 63.3, 35);
            ytabello = ytabello + 35;
            ykonten = ykonten + 35;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("" + lo.nama_driver, 170, ykonten + 1, { align: 'center' });
            doc.text("Telp. " + lo.nomor_telpon, 170, ykonten + 6, { align: 'center' });
        } else {
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykonten);
            doc.text("rician diatas.", 10, ykonten + 5);
            // Buat Tabel 3
            ytabello = ytabello + 20;
            ykonten = ykonten + 19;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("Diserahkan Oleh", 40, ykonten + 1, { align: 'center' });
            doc.text("Diverivikasi Oleh", 105, ykonten + 1, { align: 'center' });
            doc.text("Diterima Oleh", 170, ykonten + 1, { align: 'center' });
            doc.text("PT. BGR Logistics Indonesia", 40, ykonten + 6, { align: 'center' });
            doc.text("PT. Delapan Delapan Logistics", 105, ykonten + 6, { align: 'center' });
            doc.text("Driver", 170, ykonten + 6, { align: 'center' });
            ytabello = ytabello + 15;
            ykonten = ykonten + 15;
            doc.rect(10, ytabello, 63.3, 35);
            doc.rect(73.3, ytabello, 63.3, 35);
            doc.rect(136.6, ytabello, 63.3, 35);
            ytabello = ytabello + 35;
            ykonten = ykonten + 35;
            doc.rect(10, ytabello, 63.3, 15);
            doc.rect(73.3, ytabello, 63.3, 15);
            doc.rect(136.6, ytabello, 63.3, 15);
            doc.text("" + lo.nama_driver, 170, ykonten + 1, { align: 'center' });
            doc.text("Telp. " + lo.nomor_telpon, 170, ykonten + 6, { align: 'center' });
        }
        let no = 1;
        for (let i = 0; i < itemDokumenLO.length; i++) {
            doc.addPage();
            let totalsjt = 0;
            let ytabelsjt = 100;
            let ykontensjt = 106;
            let n = 1;
            halaman = 1;
            totalsjt = 0;
            imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
            doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
            imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
            doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            title1 = 'SURAT JALAN TRANSPORTER (SJT)';
            title2 = 'BANTUAN STUNTING';
            title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
            doc.text(title1, 105, 13, { align: 'center' });
            doc.text(title2, 105, 18, { align: 'center' });
            doc.text(title3, 105, 23, { align: 'center' });
            doc.setLineWidth(1);
            doc.line(10, 33, 200, 33);
            doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
            doc.setLineWidth(0.2);
            // Buat Tabel 1
            doc.rect(10, 50, 40, 10);
            doc.rect(50, 50, 55, 10);
            doc.rect(105, 50, 35, 10);
            doc.rect(140, 50, 60, 10);
            doc.rect(10, 60, 40, 10);
            doc.rect(50, 60, 55, 10);
            doc.rect(105, 60, 35, 10);
            doc.rect(140, 60, 60, 10);
            doc.rect(10, 70, 40, 10);
            doc.rect(50, 70, 55, 10);
            doc.rect(105, 70, 35, 10);
            doc.rect(140, 70, 60, 10);
            doc.rect(10, 80, 40, 10);
            doc.rect(50, 80, 135, 10);
            doc.rect(185, 80, 15, 10);
            doc.setFont('helvetica', 'normal');
            doc.text("Tanggal LO", 12, 56);
            doc.text("Nama Driver", 12, 66);
            doc.text("Titik Muat", 12, 76);
            doc.text("Titik Bagi", 12, 86);
            doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
            doc.text(": " + lo.nama_driver, 52, 66);
            doc.text(": " + lo.titik_muat, 52, 76);
            doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
            doc.text("Nopol Mobil", 107, 56);
            doc.text("Telpon Driver", 107, 66);
            doc.text("Jenis Muatan", 107, 76);
            doc.text(String(halaman++), 191, 86);
            doc.text(": " + lo.nopol_mobil_armada, 142, 56);
            doc.text(": " + lo.nomor_telpon, 142, 66);
            doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
            // Buat table 2
            doc.rect(10, 100, 10, 10);
            doc.rect(20, 100, 55, 10);
            doc.rect(75, 100, 55, 10);
            doc.rect(130, 100, 55, 10);
            doc.rect(185, 100, 15, 10);
            doc.text("No", 15, 106, { align: 'center' });
            doc.text("Kabupaten/Kota", 22, 106, { align: 'left' });
            doc.text("Kecamatan", 77, 106, { align: 'left' });
            doc.text("Desa/Kelurahan", 132, 106, { align: 'left' });
            doc.text("Jumlah", 192, 106, { align: 'center' });
            ytabelsjt = 100;
            ykontensjt = 106;
            for (let y = 0; y < itemLO.length; y++) {
                if (itemLO[y].titik_bagi === itemDokumenLO[i].titik_bagi) {
                    ytabelsjt = ytabelsjt + 10;
                    ykontensjt = ykontensjt + 10;
                    if (ytabelsjt > 260) {
                        doc.addPage();
                        imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                        imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                        doc.setFont('helvetica', 'bold');
                        doc.setFontSize(10);
                        title1 = 'SURAT JALAN TRANSPORTER (SJT)';
                        title2 = 'BANTUAN STUNTING';
                        title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                        doc.text(title1, 105, 13, { align: 'center' });
                        doc.text(title2, 105, 18, { align: 'center' });
                        doc.text(title3, 105, 23, { align: 'center' });
                        doc.setLineWidth(1);
                        doc.line(10, 33, 200, 33);
                        doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
                        doc.setLineWidth(0.2);
                        // Buat Tabel 1
                        doc.rect(10, 50, 40, 10);
                        doc.rect(50, 50, 55, 10);
                        doc.rect(105, 50, 35, 10);
                        doc.rect(140, 50, 60, 10);
                        doc.rect(10, 60, 40, 10);
                        doc.rect(50, 60, 55, 10);
                        doc.rect(105, 60, 35, 10);
                        doc.rect(140, 60, 60, 10);
                        doc.rect(10, 70, 40, 10);
                        doc.rect(50, 70, 55, 10);
                        doc.rect(105, 70, 35, 10);
                        doc.rect(140, 70, 60, 10);
                        doc.rect(10, 80, 40, 10);
                        doc.rect(50, 80, 135, 10);
                        doc.rect(185, 80, 15, 10);
                        doc.setFont('helvetica', 'normal');
                        doc.text("Tanggal LO", 12, 56);
                        doc.text("Nama Driver", 12, 66);
                        doc.text("Titik Muat", 12, 76);
                        doc.text("Titik Bagi", 12, 86);
                        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                        doc.text(": " + lo.nama_driver, 52, 66);
                        doc.text(": " + lo.titik_muat, 52, 76);
                        doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
                        doc.text("Nopol Mobil", 107, 56);
                        doc.text("Telpon Driver", 107, 66);
                        doc.text("Jenis Muatan", 107, 76);
                        doc.text(String(halaman++), 191, 86);
                        doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                        doc.text(": " + lo.nomor_telpon, 142, 66);
                        doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
                        // Buat table 2
                        doc.rect(10, 100, 10, 10);
                        doc.rect(20, 100, 55, 10);
                        doc.rect(75, 100, 55, 10);
                        doc.rect(130, 100, 55, 10);
                        doc.rect(185, 100, 15, 10);
                        doc.text("No", 15, 106, { align: 'center' });
                        doc.text("Kabupaten/Kota", 22, 106, { align: 'left' });
                        doc.text("Kecamatan", 77, 106, { align: 'left' });
                        doc.text("Desa/Kelurahan", 132, 106, { align: 'left' });
                        doc.text("Jumlah", 192, 106, { align: 'center' });
                        ytabelsjt = 100;
                        ykontensjt = 106;
                    } else {
                        doc.rect(10, ytabelsjt, 10, 10);
                        doc.rect(20, ytabelsjt, 55, 10);
                        doc.rect(75, ytabelsjt, 55, 10);
                        doc.rect(130, ytabelsjt, 55, 10);
                        doc.rect(185, ytabelsjt, 15, 10);
                        doc.text(String(n++), 15, ykontensjt, { align: 'center' });
                        doc.text(itemLO[y].kabupaten_kota, 22, ykontensjt, { align: 'left' });
                        doc.text(itemLO[y].kecamatan, 77, ykontensjt, { align: 'left' });
                        doc.text(itemLO[y].desa_kelurahan, 132, ykontensjt, { align: 'left' });
                        doc.text(String(itemLO[y].jumlah_muatan), 192, ykontensjt, { align: 'center' });
                    }
                    totalsjt = totalsjt + itemLO[y].jumlah_muatan;
                }
            }
            ytabelsjt = ytabelsjt + 10;
            ykontensjt = ykontensjt + 10;
            doc.rect(10, ytabelsjt, 175, 10);
            doc.rect(185, ytabelsjt, 15, 10);
            doc.text("Total", 100, ykontensjt, { align: 'center' });
            doc.text(String(totalsjt), 192, ykontensjt, { align: 'center' });
            if (ytabelsjt > 190) {
                doc.addPage();
                imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
                doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
                imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
                doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(10);
                title1 = 'SURAT JALAN TRANSPORTER (SJT)';
                title2 = 'BANTUAN STUNTING';
                title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
                doc.text(title1, 105, 13, { align: 'center' });
                doc.text(title2, 105, 18, { align: 'center' });
                doc.text(title3, 105, 23, { align: 'center' });
                doc.setLineWidth(1);
                doc.line(10, 33, 200, 33);
                doc.text("NOMOR SJT : " + lo.nomor_lo + "-" + (no), 105, 43, { align: 'center' });
                doc.setLineWidth(0.2);
                // Buat Tabel 1
                doc.rect(10, 50, 40, 10);
                doc.rect(50, 50, 55, 10);
                doc.rect(105, 50, 35, 10);
                doc.rect(140, 50, 60, 10);
                doc.rect(10, 60, 40, 10);
                doc.rect(50, 60, 55, 10);
                doc.rect(105, 60, 35, 10);
                doc.rect(140, 60, 60, 10);
                doc.rect(10, 70, 40, 10);
                doc.rect(50, 70, 55, 10);
                doc.rect(105, 70, 35, 10);
                doc.rect(140, 70, 60, 10);
                doc.rect(10, 80, 40, 10);
                doc.rect(50, 80, 135, 10);
                doc.rect(185, 80, 15, 10);
                doc.setFont('helvetica', 'normal');
                doc.text("Tanggal LO", 12, 56);
                doc.text("Nama Driver", 12, 66);
                doc.text("Titik Muat", 12, 76);
                doc.text("Titik Bagi", 12, 86);
                doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
                doc.text(": " + lo.nama_driver, 52, 66);
                doc.text(": " + lo.titik_muat, 52, 76);
                doc.text(": " + itemDokumenLO[i].titik_bagi, 52, 86);
                doc.text("Nopol Mobil", 107, 56);
                doc.text("Telpon Driver", 107, 66);
                doc.text("Jenis Muatan", 107, 76);
                doc.text(String(halaman++), 191, 86);
                doc.text(": " + lo.nopol_mobil_armada, 142, 56);
                doc.text(": " + lo.nomor_telpon, 142, 66);
                doc.text(": " + String(itemDokumenLO[i].jenis_muatan), 142, 76);
                ytabelsjt = 100;
                ykontensjt = 106;
                doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykontensjt);
                doc.text("rician diatas.", 10, ykonten + 5);
                // Buat Tabel 3
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 19;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("Diserahkan Oleh", 40, ykontensjt + 1, { align: 'center' });
                doc.text("Ditugaskan Kepada", 105, ykontensjt + 1, { align: 'center' });
                doc.text("Diterima Oleh", 170, ykontensjt + 1, { align: 'center' });
                doc.text("PT. Delapan Delapan Logistics", 40, ykontensjt + 6, { align: 'center' });
                doc.text("Driver", 105, ykontensjt + 6, { align: 'center' });
                doc.text("Petugas Titik Bagi", 170, ykontensjt + 6, { align: 'center' });
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.rect(10, ytabelsjt, 63.3, 35);
                doc.rect(73.3, ytabelsjt, 63.3, 35);
                doc.rect(136.6, ytabelsjt, 63.3, 35);
                ytabelsjt = ytabelsjt + 35;
                ykontensjt = ykontensjt + 35;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("" + lo.nama_driver, 105, ykontensjt + 1, { align: 'center' });
                doc.text("Telp. " + lo.nomor_telpon, 105, ykontensjt + 6, { align: 'center' });
            } else {
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykontensjt);
                doc.text("rician diatas.", 10, ykontensjt + 5);
                // Buat Tabel 3
                ytabelsjt = ytabelsjt + 20;
                ykontensjt = ykontensjt + 19;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("Diserahkan Oleh", 40, ykontensjt + 1, { align: 'center' });
                doc.text("Ditugaskan Kepada", 105, ykontensjt + 1, { align: 'center' });
                doc.text("Diterima Oleh", 170, ykontensjt + 1, { align: 'center' });
                doc.text("PT. Delapan Delapan Logistics", 40, ykontensjt + 6, { align: 'center' });
                doc.text("Driver", 105, ykontensjt + 6, { align: 'center' });
                doc.text("Petugas Titik Bagi", 170, ykontensjt + 6, { align: 'center' });
                ytabelsjt = ytabelsjt + 15;
                ykontensjt = ykontensjt + 15;
                doc.rect(10, ytabelsjt, 63.3, 35);
                doc.rect(73.3, ytabelsjt, 63.3, 35);
                doc.rect(136.6, ytabelsjt, 63.3, 35);
                ytabelsjt = ytabelsjt + 35;
                ykontensjt = ykontensjt + 35;
                doc.rect(10, ytabelsjt, 63.3, 15);
                doc.rect(73.3, ytabelsjt, 63.3, 15);
                doc.rect(136.6, ytabelsjt, 63.3, 15);
                doc.text("" + lo.nama_driver, 105, ykontensjt + 1, { align: 'center' });
                doc.text("Telp. " + lo.nomor_telpon, 105, ykontensjt + 6, { align: 'center' });
            }
            no++;
        }
        doc.save(lo.nomor_lo);
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Detail Loading Order (LO)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Loading Order (LO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                        <Select
                            id="id_alokasi"
                            name="id_alokasi"
                            value={selectedAlokasi}
                            onChange={handleAlokasiChange}
                            options={alokasiOption}
                            placeholder="PILIH ALOKASI"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="nomor_lo" className="form-label">Nomor LO</label>
                        <input className="form-control text-uppercase" type="text" id="nomor_lo" name='nomor_lo' placeholder="NOMOR LO" ref={inputRef} defaultValue={lo?.nomor_lo || ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="tanggal_lo" className="form-label">Tanggal LO</label>
                        <input className="form-control text-uppercase" type="date" id="tanggal_lo" name='tanggal_lo' placeholder="" ref={inputRef} defaultValue={lo ? lo.tanggal_lo.slice(0, 10) : ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="titik_muat" className="form-label">Titik Muat</label>
                        <input className="form-control text-uppercase" type="text" id="titik_muat" name='titik_muat' placeholder="TITIK MUAT" ref={inputRef} defaultValue={lo?.titik_muat || ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="nopol_mobil_armada" className="form-label">Nopol Mobil</label>
                        <input className="form-control text-uppercase" type="text" id="nopol_mobil_armada" name='nopol_mobil_armada' placeholder="NOPOL ARMADA" ref={inputRef} defaultValue={lo?.nopol_mobil_armada || ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="nama_driver" className="form-label">Nama Driver</label>
                        <input className="form-control text-uppercase" type="text" id="nama_driver" name='nama_driver' placeholder="" ref={inputRef} defaultValue={lo ? lo.nama_driver : ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="nomor_telpon" className="form-label">Telpon Driver</label>
                        <input className="form-control text-uppercase" type="text" id="nomor_telpon" name='nomor_telpon' placeholder="TITIK MUAT" ref={inputRef} defaultValue={lo?.nomor_telpon || ""} required readOnly />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="" className="form-label">Proses</label>
                        <button type="button" className="btn btn-primary w-100" onClick={() => generatePDF()} >DOWNLOAD</button>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            List Titik Drop
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-4 mb-md-0">
                <div className='table-responsive text-nowrap'>
                    <table className="table" style={{ fontSize: "13px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "5px" }} >No</th>
                                <th>KABUPATEN/KOTA</th>
                                <th>KECAMATAN</th>
                                <th>DESA/KELURAHAN</th>
                                <th>MUATAN</th>
                                <th>TITIK BAGI</th>
                                <th style={{ width: "5px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemLO.map((itemlo, index) => (
                                <tr key={index}>
                                    <td>{itemlo.nomor}</td>
                                    <td>{itemlo.kabupaten_kota}</td>
                                    <td>{itemlo.kecamatan}</td>
                                    <td>{itemlo.desa_kelurahan}</td>
                                    <td>{itemlo.jumlah_muatan} {itemlo.jenis_muatan}</td>
                                    <td>{itemlo.titik_bagi}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

DetailPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    detailId: PropTypes.number.isRequired,
    idAlokasi: PropTypes.number.isRequired
};

export default DetailPage;