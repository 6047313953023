// src/router/AppRoutes.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import PincaVendorIndexPage from '../pages/pinca/vendor/indexPage';
import PincaDriverIndexPage from '../pages/pinca/driver/indexPage';
import PincaArmadaIndexPage from '../pages/pinca/armada/indexPage';
import PincaPOIndexPage from '../pages/pinca/po/indexPage';
import PincaMoveHubIndexPage from '../pages/pinca/movehub/indexPage';
import PincaLOIndexPage from '../pages/pinca/losjt/indexPage';
import PincaRekapIndexPage from '../pages/pinca/rekapan/indexPage';
import SatgasMoveHubIndexPage from '../pages/satgas/movehub/indexPage';
import SatgasLOIndexPage from '../pages/satgas/losjt/indexPage';

const AppRoutes = () => {
    const role = localStorage.getItem('role');
    return (
        <Routes>
            {role === 'admin' && (
                <>
                    <Route path="/pinca/dashboard" element={<DashboardPage />} />
                    <Route path="/pinca/vendor" element={<PincaVendorIndexPage />} />
                </>
            )}
            {role === 'pinca' && (
                <>
                    <Route path="/pinca/dashboard" element={<DashboardPage />} />
                    <Route path="/pinca/vendor" element={<PincaVendorIndexPage />} />
                    <Route path="/pinca/driver" element={<PincaDriverIndexPage />} />
                    <Route path="/pinca/armada" element={<PincaArmadaIndexPage />} />
                    <Route path="/pinca/po" element={<PincaPOIndexPage />} />
                    <Route path="/pinca/movehub" element={<PincaMoveHubIndexPage />} />
                    <Route path="/pinca/losjt" element={<PincaLOIndexPage />} />
                    <Route path="/pinca/penyaluran" element={<PincaRekapIndexPage />} />
                </>
            )}
            {role === 'satgas' && (
                <>
                    <Route path="/satgas/dashboard" element={<DashboardPage />} />
                    <Route path="/satgas/movehub" element={<SatgasMoveHubIndexPage />} />
                    <Route path="/satgas/losjt" element={<SatgasLOIndexPage />} />
                </>
            )}
            {role === 'admin' && (
                <>
                    <Route path="/dashboard" element={<DashboardPage />} />
                </>
            )}
            {role === 'pic' && (
                <>
                    {/* Tambahkan rute yang khusus untuk PIC di sini */}
                </>
            )}
        </Routes>
    );
};

export default AppRoutes;
