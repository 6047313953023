// greetingHandler.js

const getGreetingMessage = () => {
    const pathname = window.location.href;
    const pathnameSegments = pathname.split('/');
    let greeting;
    if (pathnameSegments[4] === undefined) {
        greeting = "LOGIN"
    } else {
        greeting = pathnameSegments[4].toUpperCase();
    }
    return greeting;
};

export default getGreetingMessage;