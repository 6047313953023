import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const AddPage = ({ handlePageChange, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const id_kantor = localStorage.getItem('id_kantor');
    const id_user = localStorage.getItem('id_user');
    const navigate = useNavigate();
    const [detailId, setDetailId] = useState('');
    const [idAlokasi, setIdAlokasi] = useState('');
    const [kodeKantor, setKodeKantor] = useState('');
    const [jumlahPO, setJumlahPO] = useState('');
    const [statusPOInit, setStatusPOInit] = useState(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(null);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        const fetchKantor = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/kantor/${id_kantor}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setKodeKantor(response.data.kode_kantor);
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        };
        fetchKantor();
    }, [token, navigate]);

    const handleAlokasiChange = async (selectedOption) => {
        setSelectedAlokasi(selectedOption);
        let link_po = "";
        if (selectedOption.value == 4) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po4";
        } else if (selectedOption.value == 5) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po5";
        } else if (selectedOption.value == 6) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        } else {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        }
        console.log(link_po);
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            console.log(id_kantor);
            const response = await axios.get(link_po + "/jumlahbykantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setJumlahPO(response.data[0].jumlah_po);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bulanjadi = formData.tanggal_po.slice(5, 7);
        const tahunjadi = formData.tanggal_po.slice(0, 4);
        const nomorakhir = parseInt(jumlahPO) + 1;
        let link_po = "";
        const dataToSubmit = {
            ...formData,
            id_kantor: id_kantor,
            id_user: id_user,
            status_po: "MENUNGGU",
            nomor_po: "PO88LOG" + kodeKantor + "STNG-" + selectedAlokasi.value + "-" + bulanjadi + "" + tahunjadi + "-" + nomorakhir
        };
        console.log(dataToSubmit);
        if (selectedAlokasi.value == 4) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po4";
        } else if (selectedAlokasi.value == 5) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po5";
        } else if (selectedAlokasi.value == 6) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        } else {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        }
        console.log(selectedAlokasi.value);
        console.log(link_po);
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.post(link_po, dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Purchase Order (PO)',
                text: 'Data Berhasil Ditambahkan',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                setDetailId(response.data.poId);
                setIdAlokasi(selectedAlokasi.value);
                setStatusPOInit("MENUNGGU");
                handlePageChange('detail', response.data.poId, selectedAlokasi.value, "MENUNGGU");
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Tambah Purchase Order (PO)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Purchase Order (PO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                            <Select
                                id="id_alokasi"
                                name="id_alokasi"
                                value={selectedAlokasi}
                                onChange={handleAlokasiChange}
                                options={alokasiOption}
                                placeholder="PILIH ALOKASI"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_po" className="form-label">Tanggal PO</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_po" name='tanggal_po' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_muat" className="form-label">Tanggal Muat</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_muat" name='tanggal_muat' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_selesai" className="form-label">Estimasi Tanggal Selesai</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_selesai" name='tanggal_selesai' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="origin" className="form-label">Origin</label>
                            <input className="form-control text-uppercase" type="text" id="origin" name='origin' placeholder="ORIGIN" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input className="form-control text-uppercase" type="text" id="destination" name='destination' placeholder="DESTINATION" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">BERIKUTNYA</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

AddPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
};

export default AddPage;