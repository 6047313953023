import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const AddPage = ({ handlePageChange, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [vendorArmada, setVendorArmada] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [jenisMobil, setJenisMobil] = useState([]);
    const [selectedJenisMobil, setSelectedJenisMobil] = useState(null);
    const [formData, setFormData] = useState({
    });

    useEffect(() => {
        const fetchVendorArmadaAll = async () => {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            try {
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datavendorarmada = response.data.map(vendorarmadaall => ({
                    value: vendorarmadaall.id_vendor,
                    label: vendorarmadaall.nama_vendor
                }));
                setVendorArmada(datavendorarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchVendorArmadaAll();
    }, [token, navigate]);

    useEffect(() => {
        const fetchJenisMobil = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/jenis_mobil', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const datajenismobil = response.data.map(jenismobil => ({
                    value: jenismobil.id_jenis_mobil,
                    label: jenismobil.nama_jenis_mobil
                }));
                setJenisMobil(datajenismobil);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchJenisMobil();
    }, [token, navigate]);

    const handleVendorArmadaChange = (selectedOption) => {
        setSelectedVendor(selectedOption);
    };

    const handleJenisMobilChange = (selectedOption) => {
        setSelectedJenisMobil(selectedOption);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const dataToSubmit = {
            ...formData,
            status_armada: "TERSEDIA",
            id_vendor: selectedVendor.value,
            id_jenis_mobil: selectedJenisMobil.value,
            lokasi_terakhir: "GARASI " + selectedVendor.label,
        };
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.post('https://api.midstunting.delapandelapanlogistics.com/api/armada', dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada/${dataToSubmit.id_vendor}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const dataToUpdate = {
                status_vendor: "TERSEDIA",
            }
            await axios.put(`https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada/updatestatus/${dataToSubmit.id_vendor}`, dataToUpdate,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const dataToUpdate2 = {
                jumlah_armada: response.data.result.jumlah_armada + 1
            }
            await axios.put(`https://api.midstunting.delapandelapanlogistics.com/api/vendor_armada/updatejumlah/${dataToSubmit.id_vendor}`, dataToUpdate2,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Armada',
                text: 'Data Berhasil Ditambahkan',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                handleBackClick();
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Tambah Armada</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Armada.
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_vendor" className="form-label">VENDOR ARMADA</label>
                            <Select
                                id="id_vendor"
                                name="id_vendor"
                                value={selectedVendor}
                                onChange={handleVendorArmadaChange}
                                options={vendorArmada}
                                placeholder="PILIH VENDOR"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_jenis_mobil" className="form-label">JENIS MOBIL</label>
                            <Select
                                id="id_jenis_mobil"
                                name="id_jenis_mobil"
                                value={selectedJenisMobil}
                                onChange={handleJenisMobilChange}
                                options={jenisMobil}
                                placeholder="PILIH JENIS MOBIL"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="nopol_mobil_armada" className="form-label">Nopol Mobil</label>
                            <input className="form-control text-uppercase" type="text" id="nopol_mobil_armada" name='nopol_mobil_armada' placeholder="Nopol Mobil" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">SIMPAN</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

AddPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
};

export default AddPage;