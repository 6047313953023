import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const AddPage = ({ handlePageChange, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const id_kantor = localStorage.getItem('id_kantor');
    const id_user = localStorage.getItem('id_user');
    const navigate = useNavigate();
    const [detailId, setDetailId] = useState('');
    const [idAlokasi, setIdAlokasi] = useState('');
    const [detailIdDriver, setDetailIdDriver] = useState('');
    const [detailIdArmada, setDetailIdArmada] = useState('');
    const [detailIdMove, setDetailIdMove] = useState('');
    const [kodeKantor, setKodeKantor] = useState('');
    const [jumlahMove, setJumlahMove] = useState('');
    const [statusMoveInit, setStatusMoveInit] = useState(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(null);
    const [poOption, setPOOption] = useState([]);
    const [selectedPO, setSelectedPO] = useState(null);
    const [armadaOption, setArmadaOption] = useState([]);
    const [selectedArmada, setSelectedArmada] = useState(null);
    const [driverOption, setDriverOption] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [formData, setFormData] = useState({});
    const [jenisMuatanOption] = useState([
        { value: 'AYAM', label: 'AYAM' },
        { value: 'TELUR', label: 'TELUR' }
    ]);
    const [selectedJenisMuatan, setSelectedJenisMuatan] = useState(null);
    const handleJenisMuatanChange = (selectedOption) => {
        setSelectedJenisMuatan(selectedOption);
    };
    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        const fetchKantor = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/kantor/${id_kantor}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setKodeKantor(response.data.kode_kantor);
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        };
        fetchKantor();
    }, [token, navigate, id_kantor]);

    useEffect(() => {
        const fetchArmada = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataarmada = response.data.map(armadaall => ({
                    value: armadaall.id_armada,
                    label: armadaall.nopol_mobil_armada + " - " + armadaall.nama_jenis_mobil
                }));
                setArmadaOption(dataarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmada();
    }, [token, navigate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/driver', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const daradriver = response.data.map(driver => ({
                    value: driver.id_driver,
                    label: driver.nama_driver + " (" + driver.nomor_telpon + ")"
                }));
                setDriverOption(daradriver);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchDriver();
    }, [token, navigate]);

    const handleAlokasiChange = async (selectedOption) => {
        setSelectedAlokasi(selectedOption);
        let link_move = "";
        if (selectedOption.value === 4) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
        } else if (selectedOption.value === 5) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
        } else if (selectedOption.value === 6) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        } else {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get(link_move + "/jumlahbykantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setJumlahMove(response.data[0].jumlah_move);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
        let link_po = "";
        if (selectedOption.value === 4) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po4";
        } else if (selectedOption.value === 5) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po5";
        } else if (selectedOption.value === 6) {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        } else {
            link_po = "https://api.midstunting.delapandelapanlogistics.com/api/po6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get(link_po + "/filter/kantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const dataPO = response.data.map(poall => ({
                value: poall.result.id_po,
                label: poall.result.nomor_po + " " + poall.result.destination + " " + poall.result.items[0].jenis_muatan
            }));
            setPOOption(dataPO);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const handlePOChange = async (selectedOption) => {
        setSelectedPO(selectedOption);
    };

    const handleArmadaChange = (selectedOption) => {
        setSelectedArmada(selectedOption);
    };

    const handleDriverChange = (selectedOption) => {
        setSelectedDriver(selectedOption);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bulanjadi = formData.tanggal_move.slice(5, 7);
        const tahunjadi = formData.tanggal_move.slice(0, 4);
        const nomorakhir = parseInt(jumlahMove) + 1;
        let link_move = "";
        const dataToSubmit = {
            ...formData,
            id_kantor: id_kantor,
            id_user: id_user,
            status_move: "MENUNGGU",
            nomor_move: "LOMOVE88LOG" + kodeKantor + "STNG-" + selectedAlokasi.value + "-" + bulanjadi + "" + tahunjadi + "-" + nomorakhir,
            id_driver: selectedDriver.value,
            id_armada: selectedArmada.value,
            id_po: selectedPO.value,
            jenis_muatan: selectedJenisMuatan.value
        };
        if (selectedAlokasi.value === 4) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
        } else if (selectedAlokasi.value === 5) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
        } else if (selectedAlokasi.value === 6) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        } else {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.post(link_move, dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Loading Order (LO) Move Hub',
                text: 'Data Berhasil Ditambahkan',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                setDetailId(response.data.loId);
                setIdAlokasi(selectedAlokasi.value);
                setStatusMoveInit("MENUNGGU");
                handlePageChange('detail', response.data.moveId, selectedAlokasi.value, "MENUNGGU", selectedDriver.value, selectedAlokasi.value, selectedJenisMuatan.value);
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6 fw-bold">Data Loading Order (LO) Move Hub</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Loading Order (LO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                            <Select
                                id="id_alokasi"
                                name="id_alokasi"
                                value={selectedAlokasi}
                                onChange={handleAlokasiChange}
                                options={alokasiOption}
                                placeholder="PILIH ALOKASI"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_po" className="form-label">NOMOR PO</label>
                            <Select
                                id="id_po"
                                name="id_po"
                                value={selectedPO}
                                onChange={handlePOChange}
                                options={poOption}
                                placeholder="PILIH PO"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_move" className="form-label">Tanggal Move</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_move" name='tanggal_move' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_muat_move" className="form-label">Tanggal Muat</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_muat_move" name='tanggal_muat_move' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_drop_move" className="form-label">Tanggal Drop</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_drop_move" name='tanggal_drop_move' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_armada" className="form-label">ARMADA</label>
                            <Select
                                id="id_armada"
                                name="id_armada"
                                value={selectedArmada}
                                onChange={handleArmadaChange}
                                options={armadaOption}
                                placeholder="PILIH ARMADA"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_driver" className="form-label">DRIVER</label>
                            <Select
                                id="id_driver"
                                name="id_driver"
                                value={selectedDriver}
                                onChange={handleDriverChange}
                                options={driverOption}
                                placeholder="PILIH DRIVER"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="origin" className="form-label">Titik Muat</label>
                            <input className="form-control" type="text" id="origin" name='origin' placeholder="TITIK MUAT" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="destination" className="form-label">HUB 88 Logistics</label>
                            <input className="form-control" type="text" id="destination" name='destination' placeholder="HUB 88 Logistics" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="jenis_muatan" className="form-label">JENIS MUATAN</label>
                            <Select
                                id="jenis_muatan"
                                name="jenis_muatan"
                                value={selectedJenisMuatan}
                                onChange={handleJenisMuatanChange}
                                options={jenisMuatanOption}
                                placeholder="PILIH JENIS MUATAN"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="jumlah_muatan" className="form-label">Jumlah Muatan</label>
                            <input className="form-control" type="text" id="jumlah_muatan" name='jumlah_muatan' placeholder="0" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">BERIKUTNYA</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

AddPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired
};

export default AddPage;