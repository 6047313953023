import React, { useState, useEffect } from 'react';
import DetailPage from './detailPage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const IndexLOPage = () => {
    const token = localStorage.getItem('token');
    const id_user = localStorage.getItem('id_user');
    const id_kantor = localStorage.getItem('id_kantor');
    const navigate = useNavigate();
    const [currentView, setCurrentView] = useState('index');
    const [detailId, setDetailId] = useState(null);
    const [idAlokasi, setIdAlokasi] = useState(null);
    const [statusPOInit, setStatusPOInit] = useState(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(null);
    const [penyaluranAyamFiltered, setPenyaluranAyamFiltered] = useState([]);
    const [tanggalAwal, setTanggalAwal] = useState(null);
    const [tanggalAkhir, setTanggalAkhir] = useState(null);
    const [formFilter] = useState({});
    let fiterLengkap = true;
    let nomor = 1;
    let lastNomorLO = '';
    let lastTanggalLO = '';
    let totalMuatanPerLO = 0;
    let totalMuatanPerTanggal = 0;
    let totalKeseluruhan = 0;
    let idLOLama = "";

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    const handlePageChange = (page, id = null, idalokasi = null, statuspoinit) => {
        setCurrentView(page);
        if (id !== null) {
            setDetailId(id);
            setIdAlokasi(idalokasi);
            setStatusPOInit(statuspoinit);
        }
    };

    const handleBackClick = () => {
        setCurrentView('index');
        showFilter("ALL", "ALL", "ALL");
    };

    const handleAlokasiChange = (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const handleTanggalAwalChange = (e) => {
        const { value } = e.target;
        setTanggalAwal(value);
    };

    const handleTanggalAkhirChange = (e) => {
        const { value } = e.target;
        setTanggalAkhir(value);
    };

    const showFilter = async (alokasi) => {
        console.log(alokasi);
        if (!alokasi) {
            fiterLengkap = false;
        }
        if (!fiterLengkap) {
            Swal.fire({
                title: 'Filter Data',
                text: 'Pilih Alokasi dan Status Terlebih Dahulu',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
        } else {
            const dataToSubmit = ({
                ...formFilter,
                tanggal_awal: tanggalAwal,
                tanggal_akhir: tanggalAkhir,
                id_kantor: id_kantor,
            });

            console.log(dataToSubmit);

            let link_lo = "";
            if (selectedAlokasi.value == 4) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
            } else if (selectedAlokasi.value == 5) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
            } else if (selectedAlokasi.value == 6) {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
            } else {
                link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
            }
            try {
                const response = await axios.put(`${link_lo}/filter/rekapbykantorayam`, dataToSubmit, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                let total = 0;
                const rekapayamfiltered = response.data.map(datarekap => (
                    {
                        id_lo: datarekap.id_lo,
                        tanggal_lo: datarekap.tanggal_lo,
                        nomor_lo: datarekap.nomor_lo,
                        titik_muat: datarekap.titik_muat,
                        nama_kantor: datarekap.nama_kantor,
                        nama_driver: datarekap.nama_driver,
                        nomor_telpon: datarekap.nomor_telpon,
                        nopol_mobil_armada: datarekap.nopol_mobil_armada,
                        nama_jenis_mobil: datarekap.nama_jenis_mobil,
                        nama_kabupaten_kota: datarekap.nama_kabupaten_kota,
                        nama_kecamatan: datarekap.nama_kecamatan,
                        nama_desa_kelurahan: datarekap.nama_desa_kelurahan,
                        jumlah_muatan: datarekap.jumlah_muatan,
                        jenis_muatan: datarekap.jenis_muatan,
                        titik_bagi: datarekap.titik_bagi,
                        total: total + datarekap.jumlah_muatan
                    }
                ));
                console.log(rekapayamfiltered);
                setPenyaluranAyamFiltered(rekapayamfiltered);
                if (response.data.length == 0) {
                    Swal.fire({
                        title: 'Data Rekap Penyaluran',
                        text: 'Data Rekap Penyaluran Tidak Ditemukan',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        position: 'center',
                        timerProgressBar: true
                    });
                    setPenyaluranAyamFiltered([]);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Data Rekap Penyaluran',
                    text: 'Data Rekap Penyaluran Tidak Ditemukan',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    position: 'center',
                    timerProgressBar: true
                });
                setPenyaluranAyamFiltered([]);
            }
        }
    };

    const handleClick = (identifier) => {
        // Logika yang ingin Anda jalankan saat tautan diklik, misalnya menavigasi ke detail atau melakukan sesuatu dengan identifier
        console.log(`Clicked on: ${identifier}`);
        // Tambahkan logika lain sesuai kebutuhan
    };

    return (
        <div>
            {currentView === 'index' && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="divider text-start">
                                <div className="divider-text">
                                    <span className="menu-header-text fs-6 fw-bold">Data Rekap Penyaluran</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label htmlFor="tanggal_awal" className="form-label">Tanggal (Awal)</label>
                                <input className="form-control text-uppercase" type="date" id="tanggal_awal" name='tanggal_awal' placeholder="Nopol Mobil" onChange={handleTanggalAwalChange} required />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label htmlFor="tanggal_akhir" className="form-label">Tanggal (Akhir)</label>
                                <input className="form-control text-uppercase" type="date" id="tanggal_akhir" name='tanggal_akhir' placeholder="Nopol Mobil" onChange={handleTanggalAkhirChange} required />
                            </div>
                            <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                                <label className="form-label">Alokasi</label>
                                <Select
                                    value={selectedAlokasi}
                                    onChange={handleAlokasiChange}
                                    options={alokasiOption}
                                    placeholder="PILIH ALOKASI"
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-3">
                                <label className="form-label">FILTER</label>
                                <button type="button" className="btn btn-primary w-100" onClick={() => showFilter(
                                    selectedAlokasi
                                )} >TAMPILKAN</button>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <div className="divider text-start fw-bold">
                                        <div className="divider-text">
                                            Rekap Penyaluran Ayam
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4 mb-md-0">
                                <div className='table-responsive text-nowrap'>
                                    <table className="table" style={{ fontSize: "13px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5px" }} >No</th>
                                                <th>Tanggal LO</th>
                                                <th>Nomor LO</th>
                                                <th>Nopol Mobil</th>
                                                <th>Jenis Mobil</th>
                                                <th>Driver</th>
                                                <th>Kabupaten/Kota</th>
                                                <th>Kecamatan</th>
                                                <th>Desa/Kelurahan</th>
                                                <th>Muatan</th>
                                                <th>Titik Bagi</th>
                                                <th style={{ width: "5px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {penyaluranAyamFiltered.map((item, index) => {
                                                let rowTotalLO = null;
                                                let rowTotalTanggal = null;
                                                // Jika tanggal LO berubah, tampilkan total muatan untuk tanggal sebelumnya
                                                if (lastTanggalLO !== '' && lastTanggalLO !== item.tanggal_lo) {
                                                    rowTotalTanggal = (
                                                        <tr key={`total-tanggal-${lastTanggalLO}`} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                                            <td colSpan="9">TOTAL UNTUK TANGGAL : {lastTanggalLO.slice(8, 10)}/{lastTanggalLO.slice(5, 7)}/{lastTanggalLO.slice(0, 4)}</td>
                                                            <td>{totalMuatanPerTanggal}</td>
                                                            <td></td>
                                                        </tr>
                                                    );
                                                    totalMuatanPerTanggal = 0;  // Reset total untuk tanggal baru
                                                }

                                                // Jika nomor LO berubah, tampilkan total muatan untuk LO sebelumnya
                                                if (lastNomorLO !== '' && lastNomorLO !== item.nomor_lo) {
                                                    rowTotalLO = (
                                                        <tr key={`total-${lastNomorLO}`} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                                            <td colSpan="9">TOTAL UNTUK LO : {lastNomorLO}</td>
                                                            <td>{totalMuatanPerLO}</td>
                                                            <td></td>
                                                        </tr>
                                                    );
                                                    totalMuatanPerLO = 0;  // Reset total untuk LO baru
                                                }

                                                // Update total keseluruhan
                                                totalKeseluruhan += item.jumlah_muatan;  // Tambahkan jumlah muatan ke total keseluruhan

                                                // Update last values and totals
                                                lastNomorLO = item.nomor_lo;
                                                lastTanggalLO = item.tanggal_lo;
                                                totalMuatanPerLO += item.jumlah_muatan;
                                                totalMuatanPerTanggal += item.jumlah_muatan;

                                                // Render baris data
                                                return (
                                                    <React.Fragment key={index}>
                                                        {rowTotalTanggal /* Render baris total per tanggal jika ada */}
                                                        {rowTotalLO /* Render baris total per LO jika ada */}
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item.tanggal_lo.slice(8, 10)}/{item.tanggal_lo.slice(5, 7)}/{item.tanggal_lo.slice(0, 4)}</td>
                                                            <td>{item.nomor_lo}</td>
                                                            <td>{item.nopol_mobil_armada}</td>
                                                            <td>{item.nama_jenis_mobil}</td>
                                                            <td>{item.nama_driver} ({item.nomor_telpon})</td>
                                                            <td>{item.nama_kabupaten_kota}</td>
                                                            <td>{item.nama_kecamatan}</td>
                                                            <td>{item.nama_desa_kelurahan}</td>
                                                            <td>{item.jumlah_muatan}</td>
                                                            <td>{item.titik_bagi}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            {/* Tampilkan TOTAL UNTUK LO : terakhir */}
                                            {totalMuatanPerLO > 0 && (
                                                <tr key={`total-${lastNomorLO}`} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                                    <td colSpan="9">TOTAL UNTUK LO : {lastNomorLO}</td>
                                                    <td>{totalMuatanPerLO}</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                            {/* Tampilkan TOTAL UNTUK TANGGAL : terakhir */}
                                            {totalMuatanPerLO > 0 && (
                                                <tr key={`total-tanggal-${lastTanggalLO}`} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                                    <td colSpan="9">TOTAL UNTUK TANGGAL : {lastTanggalLO.slice(8, 10)}/{lastTanggalLO.slice(5, 7)}/{lastTanggalLO.slice(0, 4)}</td>
                                                    <td>{totalMuatanPerTanggal}</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                            {/* Tampilkan TOTAL KESELURUHAN */}
                                            {totalKeseluruhan > 0 && (
                                                <tr key="total-keseluruhan" style={{ fontWeight: 'bold', backgroundColor: '#d0d0d0' }}>
                                                    <td colSpan="9">TOTAL KESELURUHAN</td>
                                                    <td>{totalKeseluruhan}</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentView === 'detail' && <DetailPage handlePageChange={handlePageChange} detailId={detailId} idAlokasi={idAlokasi} statusPOInit={statusPOInit} handleBackClick={handleBackClick} />}
        </div>
    );
};

export default IndexLOPage;