import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const AddPage = ({ handlePageChange, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const id_kantor = localStorage.getItem('id_kantor');
    const id_user = localStorage.getItem('id_user');
    const navigate = useNavigate();
    const [detailId, setDetailId] = useState('');
    const [idAlokasi, setIdAlokasi] = useState('');
    const [detailIdDriver, setDetailIdDriver] = useState('');
    const [detailIdArmada, setDetailIdArmada] = useState('');
    const [detailIdMove, setDetailIdMove] = useState('');
    const [kodeKantor, setKodeKantor] = useState('');
    const [jumlahLO, setJumlahLO] = useState('');
    const [statusPOInit, setStatusPOInit] = useState(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [selectedAlokasi, setSelectedAlokasi] = useState(null);
    const [armadaOption, setArmadaOption] = useState([]);
    const [selectedArmada, setSelectedArmada] = useState(null);
    const [driverOption, setDriverOption] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [moveOption, setMoveOption] = useState([]);
    const [selectedMove, setSelectedMove] = useState(null);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        const fetchKantor = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get(`https://api.midstunting.delapandelapanlogistics.com/api/kantor/${id_kantor}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setKodeKantor(response.data.kode_kantor);
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        };
        fetchKantor();
    }, [token, navigate, id_kantor]);

    useEffect(() => {
        const fetchArmada = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataarmada = response.data.map(armadaall => ({
                    value: armadaall.id_armada,
                    label: armadaall.nopol_mobil_armada + " - " + armadaall.nama_jenis_mobil
                }));
                setArmadaOption(dataarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmada();
    }, [token, navigate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/driver', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const daradriver = response.data.map(driver => ({
                    value: driver.id_driver,
                    label: driver.nama_driver + " (" + driver.nomor_telpon + ")"
                }));
                setDriverOption(daradriver);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchDriver();
    }, [token, navigate]);

    const handleAlokasiChange = async (selectedOption) => {
        setSelectedAlokasi(selectedOption);
        let link_lo = "";
        if (selectedOption.value === 4) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
        } else if (selectedOption.value === 5) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
        } else if (selectedOption.value === 6) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        } else {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get(link_lo + "/jumlahbykantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setJumlahLO(response.data[0].jumlah_lo);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
        let link_move = "";
        if (selectedOption.value === 4) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
        } else if (selectedOption.value === 5) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
        } else if (selectedOption.value === 6) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        } else {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.get(link_move + "/kantor/" + id_kantor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const datamove = response.data.map(moveall => ({
                value: moveall.id_move,
                label: moveall.nomor_move + " " + moveall.destination + " " + moveall.jenis_muatan
            }));
            setMoveOption(datamove);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const handleArmadaChange = (selectedOption) => {
        setSelectedArmada(selectedOption);
    };

    const handleDriverChange = (selectedOption) => {
        setSelectedDriver(selectedOption);
    };

    const handleMoveChange = (selectedOption) => {
        setSelectedMove(selectedOption);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bulanjadi = formData.tanggal_lo.slice(5, 7);
        const tahunjadi = formData.tanggal_lo.slice(0, 4);
        const nomorakhir = parseInt(jumlahLO) + 1;
        let link_lo = "";
        const dataToSubmit = {
            ...formData,
            id_kantor: id_kantor,
            id_user: id_user,
            status_lo: "MENUNGGU",
            nomor_lo: "LO88LOG" + kodeKantor + "STNG-" + selectedAlokasi.value + "-" + bulanjadi + "" + tahunjadi + "-" + nomorakhir,
            id_driver: selectedDriver.value,
            id_armada: selectedArmada.value,
            id_move: selectedMove.value
        };
        if (selectedAlokasi.value === 4) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo4";
        } else if (selectedAlokasi.value === 5) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo5";
        } else if (selectedAlokasi.value === 6) {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        } else {
            link_lo = "https://api.midstunting.delapandelapanlogistics.com/api/lo6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            const response = await axios.post(link_lo, dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Loading Order (LO)',
                text: 'Data Berhasil Ditambahkan',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                setDetailId(response.data.id_lo);
                setIdAlokasi(selectedAlokasi.value);
                setStatusPOInit("MENUNGGU");
                setDetailIdDriver(response.data.id_driver);
                setDetailIdArmada(response.data.id_armada);
                setDetailIdMove(response.data.id_move);
                handlePageChange('detail', response.data.id_lo, selectedAlokasi.value, "MENUNGGU", response.data.id_driver, response.data.id_armada, response.data.id_move);
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Tambah Loading Order (LO)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Loading Order (LO).
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <form id="form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                            <Select
                                id="id_alokasi"
                                name="id_alokasi"
                                value={selectedAlokasi}
                                onChange={handleAlokasiChange}
                                options={alokasiOption}
                                placeholder="PILIH ALOKASI"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_move" className="form-label">NOMOR MOVE</label>
                            <Select
                                id="id_move"
                                name="id_move"
                                value={selectedMove}
                                onChange={handleMoveChange}
                                options={moveOption}
                                placeholder="PILIH NOMOR MOVE"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="tanggal_lo" className="form-label">Tanggal LO</label>
                            <input className="form-control text-uppercase" type="date" id="tanggal_lo" name='tanggal_lo' placeholder="" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_armada" className="form-label">ARMADA</label>
                            <Select
                                id="id_armada"
                                name="id_armada"
                                value={selectedArmada}
                                onChange={handleArmadaChange}
                                options={armadaOption}
                                placeholder="PILIH ARMADA"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                            <label htmlFor="id_driver" className="form-label">DRIVER</label>
                            <Select
                                id="id_driver"
                                name="id_driver"
                                value={selectedDriver}
                                onChange={handleDriverChange}
                                options={driverOption}
                                placeholder="PILIH DRIVER"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="titik_muat" className="form-label">Titik Muat</label>
                            <input className="form-control" type="text" id="titik_muat" name='titik_muat' placeholder="TITIK MUAT" onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="" className="form-label">Proses</label>
                            <button type="submit" className="btn btn-primary w-100">BERIKUTNYA</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

AddPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
};

export default AddPage;