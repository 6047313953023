// src/pages/auth/LoginPage.jsx
import { useState } from 'react';
import './page-auth.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AuthWrapper } from './AuthWrapper';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.midstunting.delapandelapanlogistics.com/api/login', formData);
            const token = response.data.token;
            const role = response.data.user.role;
            const id_user = response.data.user.id_user;
            const id_kantor = response.data.user.id_kantor;
            const nama_user = response.data.user.nama_user;
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
            localStorage.setItem('id_user', id_user);
            localStorage.setItem('id_kantor', id_kantor);
            localStorage.setItem('nama_user', nama_user);
            Swal.fire({
                title: 'Login',
                text: `Login Berhasil, selamat datang ${nama_user}`,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                if (role == "pinca") {
                    navigate('/pinca/dashboard');
                } else if (role == "satgas") {
                    navigate('/satgas/dashboard');
                } else if (role == "pic") {
                    navigate('/pic/dashboard');
                }
            });
        } catch (error) {
            console.error('Error submitting data:', error);
            Swal.fire({
                title: 'Login',
                text: `Login gagal, periksa kembali username dan password`,
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            });
        }
    };

    return (
        <AuthWrapper>
            <h4 className="mb-2">Login !</h4>
            <p className="mb-4">Sistem Informasi Bantuan Stunting Pemerintah Tahun 2024</p>

            <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={handleChange}
                        name="username"
                        placeholder="USERNAME"
                        autoFocus />
                </div>
                <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">Password</label>
                    </div>
                    <div className="input-group input-group-merge">
                        <input
                            type="password"
                            autoComplete="true"
                            id="password"
                            onChange={handleChange}
                            className="form-control"
                            name="password"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            aria-describedby="password" />
                        <span className="input-group-text cursor-pointer"></span>
                    </div>
                </div>
                <div className="mt-5">
                    <button className="btn btn-primary d-grid w-100" type="submit">Login</button>
                </div>
            </form>
        </AuthWrapper>
    );
};

export default LoginPage;
