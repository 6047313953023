import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";


const DetailPage = ({ handlePageChange, detailId, idAlokasi, detailIdDriver, detailIdArmada, detailJenisMuatan, handleBackClick }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [alokasiOption, setAlokasiOption] = useState([]);
    const [armadaOption, setArmadaOption] = useState([]);
    const [driverOption, setDriverOption] = useState([]);
    const [jenisMuatanOption] = useState([
        { value: 'AYAM', label: 'AYAM' },
        { value: 'TELUR', label: 'TELUR' }
    ]);
    const initialAlokasiValue = alokasiOption.find(option => option.value === idAlokasi) || null;
    const initialJenisMuatanValue = jenisMuatanOption.find(option => option.value === detailJenisMuatan) || null;
    const initialIdDriverValue = driverOption.find(option => option.value === detailIdDriver) || null;
    const initialIdArmadaValue = armadaOption.find(option => option.value === detailIdArmada) || null;
    const [selectedAlokasi, setSelectedAlokasi] = useState(initialAlokasiValue);
    const [selectedJenisMuatan, setSelectedJenisMuatan] = useState(initialJenisMuatanValue);
    const [selectedArmada, setSelectedArmada] = useState(initialIdDriverValue);
    const [selectedDriver, setSelectedDriver] = useState(initialIdArmadaValue);

    const [move, setMove] = useState({
        id_move: "",
        id_kantor: "",
        id_user: "",
        tanggal_move: "",
        tanggal_muat_move: "",
        tanggal_drop_move: "",
        origin: "",
        destination: "",
        status_move: "",
        nomor_move: "",
        id_driver: "",
        id_armada: "",
        jumlah_muatan: "",
        jenis_muatan: ""
    });

    const [formData, setFormData] = useState({
        id_move: move?.id_move || "",
        id_kantor: move?.id_kantor || "",
        id_user: move?.id_user || "",
        tanggal_move: move?.tanggal_move || "",
        tanggal_muat_move: move?.tanggal_muat_move || "",
        tanggal_drop_move: move?.tanggal_drop_move || "",
        origin: move?.origin || "",
        destination: move?.destination || "",
        status_move: move?.status_move || "",
        nomor_move: move?.nomor_move || "",
        id_driver: move?.id_driver || "",
        id_armada: move?.id_armada || "",
        jumlah_muatan: move?.jumlah_muatan || "",
        jenis_muatan: move?.jenis_muatan || "",
    });

    useEffect(() => {
        const fetchArmada = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/armada', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataarmada = response.data.map(armadaall => ({
                    value: armadaall.id_armada,
                    label: armadaall.nopol_mobil_armada + " - " + armadaall.nama_jenis_mobil
                }));
                setArmadaOption(dataarmada);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchArmada();
    }, [token, navigate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/driver', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const daradriver = response.data.map(driver => ({
                    value: driver.id_driver,
                    label: driver.nama_driver + " (" + driver.nomor_telpon + ")"
                }));
                setDriverOption(daradriver);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchDriver();
    }, [token, navigate]);

    useEffect(() => {
        setFormData({
            id_move: move?.id_move || "",
            id_kantor: move?.id_kantor || "",
            id_user: move?.id_user || "",
            tanggal_move: move?.tanggal_move || "",
            tanggal_muat_move: move?.tanggal_muat_move || "",
            tanggal_drop_move: move?.tanggal_drop_move || "",
            origin: move?.origin || "",
            destination: move?.destination || "",
            status_move: move?.status_move || "",
            nomor_move: move?.nomor_move || "",
            id_driver: move?.id_driver || "",
            id_armada: move?.id_armada || "",
            jumlah_muatan: move?.jumlah_muatan || "",
            jenis_muatan: move?.jenis_muatan || "",
        });
    }, [move]);


    const fetchMove = async () => {
        if (!token) {
            Swal.fire({
                title: 'Session Expired',
                text: 'Please login to continue',
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate('/login');
            });
            return;
        }
        try {
            let link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
            if (idAlokasi === 4) {
                link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
            } else if (idAlokasi === 5) {
                link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
            } else if (idAlokasi === 6) {
                link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
            } else {
                link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
            }
            const response = await axios.get(`${link_move}/${detailId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMove(response.data);
        } catch (error) {
            console.error('Error fetching', error);
        }
    };

    useEffect(() => {
        fetchMove();
    });

    useEffect(() => {
        const fetchAlokasi = async () => {
            try {
                if (!token) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Please login to continue',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login');
                    });
                    return;
                }
                const response = await axios.get('https://api.midstunting.delapandelapanlogistics.com/api/alokasi', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const dataAlokasi = response.data.data.map(alokasiall => ({
                    value: alokasiall.id_alokasi,
                    label: alokasiall.nama_alokasi
                }));
                setAlokasiOption(dataAlokasi);
            } catch (error) {
                console.error('Error fetching', error);
            }
        };
        fetchAlokasi();
    }, [token, navigate]);

    useEffect(() => {
        setSelectedAlokasi(initialAlokasiValue);
    }, [alokasiOption, initialAlokasiValue]);

    useEffect(() => {
        setSelectedJenisMuatan(initialJenisMuatanValue);
    }, [jenisMuatanOption, initialJenisMuatanValue]);

    useEffect(() => {
        setSelectedDriver(initialIdDriverValue);
    }, [driverOption, initialIdDriverValue]);

    useEffect(() => {
        setSelectedArmada(initialIdArmadaValue);
    }, [armadaOption, initialIdArmadaValue]);

    const handleAlokasiChange = (selectedOption) => {
        setSelectedAlokasi(selectedOption);
    };

    const handleJenisMuatanChange = (selectedOption) => {
        setSelectedJenisMuatan(selectedOption);
    };

    const handleArmadaChange = (selectedOption) => {
        setSelectedArmada(selectedOption);
    };

    const handleDriverChange = (selectedOption) => {
        setSelectedDriver(selectedOption);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        const dataToSubmit = ({
            ...formData,
            'jenis_muatan': selectedJenisMuatan.value,
            'id_driver': selectedDriver.value,
            'id_armada': selectedArmada.value,
            'tanggal_move': formData.tanggal_move.slice(0, 10),
            'tanggal_muat_move': formData.tanggal_muat_move.slice(0, 10),
            'tanggal_drop_move': formData.tanggal_drop_move.slice(0, 10)
        });
        let link_move = "";
        if (idAlokasi === 4) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move4";
        } else if (idAlokasi === 5) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move5";
        } else if (idAlokasi === 6) {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        } else {
            link_move = "https://api.midstunting.delapandelapanlogistics.com/api/move6";
        }
        try {
            if (!token) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login to continue',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    navigate('/login');
                });
                return;
            }
            await axios.put(`${link_move}/${detailId}`,
                dataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            Swal.fire({
                title: 'Data Loading Order (LO) Move Hub',
                text: 'Data Berhasil Diperbaharui',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                position: 'center',
                timerProgressBar: true
            }).then(() => {
                handleBackClick();
            });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'Session expired, please login again',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                });
            }
        }
    };

    const generatePDF = async () => {
        let halaman = 1;
        const tanggaljadi = move.tanggal_move.slice(8, 10);
        const bulanjadi = move.tanggal_move.slice(5, 7);
        const tahunjadi = move.tanggal_move.slice(0, 4);
        const doc = new jsPDF();
        let imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
        let imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        let title1 = 'BERITA ACARA SERAH TERIMA (BAST)';
        let title2 = 'BANTUAN STUNTING';
        let title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
        doc.text(title1, 105, 13, { align: 'center' });
        doc.text(title2, 105, 18, { align: 'center' });
        doc.text(title3, 105, 23, { align: 'center' });
        doc.setLineWidth(1);
        doc.line(10, 33, 200, 33);
        doc.text("NOMOR LO : " + move.nomor_move, 105, 43, { align: 'center' });
        doc.setLineWidth(0.2);
        // Buat Tabel 1
        doc.rect(10, 50, 40, 10);
        doc.rect(50, 50, 55, 10);
        doc.rect(105, 50, 35, 10);
        doc.rect(140, 50, 60, 10);
        doc.rect(10, 60, 40, 10);
        doc.rect(50, 60, 55, 10);
        doc.rect(105, 60, 35, 10);
        doc.rect(140, 60, 60, 10);
        doc.rect(10, 70, 40, 10);
        doc.rect(50, 70, 55, 10);
        doc.rect(105, 70, 35, 10);
        doc.rect(140, 70, 60, 10);
        doc.setFont('helvetica', 'normal');
        doc.text("Tanggal LO", 12, 56);
        doc.text("Nama Driver", 12, 66);
        doc.text("Titik Muat", 12, 76);
        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
        doc.text(": " + move.nama_driver, 52, 66);
        doc.text(": " + move.origin, 52, 76);
        doc.text("Nopol Mobil", 107, 56);
        doc.text("Telpon Driver", 107, 66);
        doc.text("Halaman", 107, 76);
        doc.text(": " + move.nopol_mobil_armada, 142, 56);
        doc.text(": " + move.nomor_telpon, 142, 66);
        doc.text(": " + String(halaman), 142, 76);
        // Buat table 2
        doc.rect(10, 90, 10, 10);
        doc.rect(20, 90, 25, 10);
        doc.rect(45, 90, 30, 10);
        doc.rect(75, 90, 30, 10);
        doc.rect(105, 90, 95, 10);
        doc.text("No", 15, 96, { align: 'center' });
        doc.text("Item", 32, 96, { align: 'center' });
        doc.text("Jumlah", 60, 96, { align: 'center' });
        doc.text("Keterangan", 90, 96, { align: 'center' });
        doc.text("Alamat Hub", 150, 96, { align: 'center' });
        let ytabello = 90;
        let ykonten = 96;
        ykonten = ykonten + 10;
        ytabello = ytabello + 10;
        doc.rect(10, ytabello, 10, 10);
        doc.rect(20, ytabello, 25, 10);
        doc.rect(45, ytabello, 30, 10);
        doc.rect(75, ytabello, 30, 10);
        doc.rect(105, ytabello, 95, 10);
        doc.text("1", 15, ykonten, { align: 'center' });
        doc.text(move.jenis_muatan, 22, ykonten, { align: 'left' });
        doc.text(String(move.jumlah_muatan), 60, ykonten, { align: 'center' });
        doc.text("Kondisi Baik", 80, ykonten, { align: 'left' });
        doc.text(move.destination, 107, ykonten, { align: 'left' })
        ytabello = ytabello + 10;
        ykonten = ykonten + 10;
        doc.rect(10, ytabello, 10, 10);
        doc.rect(20, ytabello, 25, 10);
        doc.rect(45, ytabello, 30, 10);
        doc.rect(75, ytabello, 30, 10);
        doc.rect(105, ytabello, 95, 10);
        doc.text("Total", 32, ykonten, { align: 'center' });
        doc.text("100", 60, ykonten, { align: 'center' });
        ytabello = 135;
        ykonten = 136;
        doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykonten);
        doc.text("rician diatas.", 10, ykonten + 5);
        ytabello = ytabello + 15;
        ykonten = ykonten + 19;
        doc.rect(10, ytabello, 63.3, 15);
        doc.rect(73.3, ytabello, 63.3, 15);
        doc.rect(136.6, ytabello, 63.3, 15);
        doc.text("Diserahkan Oleh", 40, ykonten + 1, { align: 'center' });
        doc.text("Diverivikasi Oleh", 105, ykonten + 1, { align: 'center' });
        doc.text("Diterima Oleh", 170, ykonten + 1, { align: 'center' });
        doc.text("PT. BGR Logistics Indonesia", 40, ykonten + 6, { align: 'center' });
        doc.text("PT. Delapan Delapan Logistics", 105, ykonten + 6, { align: 'center' });
        doc.text("Driver", 170, ykonten + 6, { align: 'center' });
        ytabello = ytabello + 15;
        ykonten = ykonten + 15;
        doc.rect(10, ytabello, 63.3, 35);
        doc.rect(73.3, ytabello, 63.3, 35);
        doc.rect(136.6, ytabello, 63.3, 35);
        ytabello = ytabello + 35;
        ykonten = ykonten + 35;
        doc.rect(10, ytabello, 63.3, 15);
        doc.rect(73.3, ytabello, 63.3, 15);
        doc.rect(136.6, ytabello, 63.3, 15);
        doc.text("" + move.nama_driver, 170, ykonten + 1, { align: 'center' });
        doc.text("Telp. " + move.nomor_telpon, 170, ykonten + 6, { align: 'center' });

        doc.addPage();
        let ytabelsjt = 100;
        let ykontensjt = 106;
        halaman = 1;
        imageUrlKiri = `${process.env.PUBLIC_URL}/assets/img/logos/bgr.png`;
        doc.addImage(imageUrlKiri, 'PNG', 10, 10, 40, 15);
        imageUrlKanan = `${process.env.PUBLIC_URL}/assets/img/logos/logosmall.png`;
        doc.addImage(imageUrlKanan, 'PNG', 160, 10, 40, 12, 'myImageAlias', 'MEDIUM');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        title1 = 'SURAT JALAN TRANSPORTER (SJT)';
        title2 = 'BANTUAN STUNTING';
        title3 = 'BATCH ' + initialAlokasiValue.value + ' TAHUN 2024';
        doc.text(title1, 105, 13, { align: 'center' });
        doc.text(title2, 105, 18, { align: 'center' });
        doc.text(title3, 105, 23, { align: 'center' });
        doc.setLineWidth(1);
        doc.line(10, 33, 200, 33);
        doc.text("NOMOR SJT : " + move.nomor_move + "-1", 105, 43, { align: 'center' });
        doc.setLineWidth(0.2);
        // Buat Tabel 1
        doc.rect(10, 50, 40, 10);
        doc.rect(50, 50, 55, 10);
        doc.rect(105, 50, 35, 10);
        doc.rect(140, 50, 60, 10);
        doc.rect(10, 60, 40, 10);
        doc.rect(50, 60, 55, 10);
        doc.rect(105, 60, 35, 10);
        doc.rect(140, 60, 60, 10);
        doc.rect(10, 70, 40, 10);
        doc.rect(50, 70, 55, 10);
        doc.rect(105, 70, 35, 10);
        doc.rect(140, 70, 60, 10);
        doc.setFont('helvetica', 'normal');
        doc.text("Tanggal LO", 12, 56);
        doc.text("Nama Driver", 12, 66);
        doc.text("Titik Muat", 12, 76);
        doc.text(": " + tanggaljadi + "/" + bulanjadi + "/" + tahunjadi, 52, 56);
        doc.text(": " + move.nama_driver, 52, 66);
        doc.text(": " + move.origin, 52, 76);
        doc.text("Nopol Mobil", 107, 56);
        doc.text("Telpon Driver", 107, 66);
        doc.text("Halaman", 107, 76);
        doc.text(": " + move.nopol_mobil_armada, 142, 56);
        doc.text(": " + move.nomor_telpon, 142, 66);
        doc.text(": 1", 142, 76);
        // Buat table 2
        doc.rect(10, 90, 10, 10);
        doc.rect(20, 90, 55, 10);
        doc.rect(75, 90, 110, 10);
        doc.rect(185, 90, 15, 10);
        doc.text("No", 15, 96, { align: 'center' });
        doc.text("Jenis Muatan", 22, 96, { align: 'left' });
        doc.text("HUB Delapan Delapan Logistics", 77, 96, { align: 'left' });
        doc.text("Jumlah", 192, 96, { align: 'center' });
        ytabelsjt = 100;
        ykontensjt = 106;
        doc.rect(10, ytabelsjt, 10, 10);
        doc.rect(20, ytabelsjt, 55, 10);
        doc.rect(75, ytabelsjt, 110, 10);
        doc.rect(185, ytabelsjt, 15, 10);
        doc.text("1", 15, ykontensjt, { align: 'center' });
        doc.text(move.jenis_muatan, 22, ykontensjt, { align: 'left' });
        doc.text(move.destination, 77, ykontensjt, { align: 'left' });
        doc.text(String(move.jumlah_muatan), 192, ykontensjt, { align: 'center' });
        ytabelsjt = ytabelsjt + 15;
        ykontensjt = ykontensjt + 15;
        doc.text("Pihak yang menyerahkan dan pihak yang menerima telah sepakat bahwa jumlah dan kondisi barang sesuai dengan", 10, ykontensjt);
        doc.text("rician diatas.", 10, ykontensjt + 5);
        ytabelsjt = ytabelsjt + 20;
        ykontensjt = ykontensjt + 19;
        doc.rect(10, ytabelsjt, 63.3, 15);
        doc.rect(73.3, ytabelsjt, 63.3, 15);
        doc.rect(136.6, ytabelsjt, 63.3, 15);
        doc.text("Diserahkan Oleh", 40, ykontensjt + 1, { align: 'center' });
        doc.text("Ditugaskan Kepada", 105, ykontensjt + 1, { align: 'center' });
        doc.text("Diterima Oleh", 170, ykontensjt + 1, { align: 'center' });
        doc.text("PT. Delapan Delapan Logistics", 40, ykontensjt + 6, { align: 'center' });
        doc.text("Driver", 105, ykontensjt + 6, { align: 'center' });
        doc.text("Petugas HUB", 170, ykontensjt + 6, { align: 'center' });
        ytabelsjt = ytabelsjt + 15;
        ykontensjt = ykontensjt + 15;
        doc.rect(10, ytabelsjt, 63.3, 35);
        doc.rect(73.3, ytabelsjt, 63.3, 35);
        doc.rect(136.6, ytabelsjt, 63.3, 35);
        ytabelsjt = ytabelsjt + 35;
        ykontensjt = ykontensjt + 35;
        doc.rect(10, ytabelsjt, 63.3, 15);
        doc.rect(73.3, ytabelsjt, 63.3, 15);
        doc.rect(136.6, ytabelsjt, 63.3, 15);
        doc.text("" + move.nama_driver, 105, ykontensjt + 1, { align: 'center' });
        doc.text("Telp. " + move.nomor_telpon, 105, ykontensjt + 6, { align: 'center' });
        doc.save(move.nomor_move);
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3">
                    <div className="divider text-start fw-bold">
                        <div className="divider-text">
                            <span className="menu-header-text fs-6">Detail Loading Order (LO) Move Hub</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="">
                    Klik <button className="fw-bold btn btn-link p-0" onClick={() => handlePageChange('index')}>disini</button> untuk kembali ke menu utama Loading Order (LO) Move Hub.
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_alokasi" className="form-label">Alokasi</label>
                        <Select
                            id="id_alokasi"
                            name="id_alokasi"
                            value={selectedAlokasi}
                            onChange={handleAlokasiChange}
                            options={alokasiOption}
                            placeholder="PILIH ALOKASI"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="tanggal_move" className="form-label">Tanggal Dokumen Move</label>
                        <input className="form-control text-uppercase" type="date" id="tanggal_move" name='tanggal_move' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={move ? move.tanggal_move.slice(0, 10) : ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="tanggal_move" className="form-label">Tanggal Muat Move</label>
                        <input className="form-control text-uppercase" type="date" id="tanggal_move" name='tanggal_move' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={move ? move.tanggal_move.slice(0, 10) : ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="tanggal_drop_move" className="form-label">Tanggal Drop Move</label>
                        <input className="form-control text-uppercase" type="date" id="tanggal_drop_move" name='tanggal_drop_move' placeholder="" onChange={handleChange} ref={inputRef} defaultValue={move ? move.tanggal_drop_move.slice(0, 10) : ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="origin" className="form-label">Titik Muat</label>
                        <input className="form-control" type="text" id="origin" name='origin' placeholder="TITIK MUAT" onChange={handleChange} ref={inputRef} defaultValue={move?.origin || ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="destination" className="form-label">HUB 88 Logistics</label>
                        <input className="form-control" type="text" id="destination" name='destination' placeholder="TITIK MUAT" onChange={handleChange} ref={inputRef} defaultValue={move?.destination || ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_armada" className="form-label">ARMADA</label>
                        <Select
                            id="id_armada"
                            name="id_armada"
                            value={selectedArmada}
                            onChange={handleArmadaChange}
                            options={armadaOption}
                            placeholder="PILIH ARMADA"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="id_driver" className="form-label">DRIVER</label>
                        <Select
                            id="id_driver"
                            name="id_driver"
                            value={selectedDriver}
                            onChange={handleDriverChange}
                            options={driverOption}
                            placeholder="PILIH DRIVER"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 col-sm-6 mb-3">
                        <label htmlFor="jenis_muatan" className="form-label">Jenis Muatan</label>
                        <Select
                            id="jenis_muatan"
                            name="jenis_muatan"
                            value={selectedJenisMuatan}
                            onChange={handleJenisMuatanChange}
                            options={jenisMuatanOption}
                            placeholder="PILIH JENIS MUATAN"
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="jumlah_muatan" className="form-label">Jumlah Muatan</label>
                        <input className="form-control" type="text" id="jumlah_muatan" name='jumlah_muatan' placeholder="0" onChange={handleChange} ref={inputRef} defaultValue={move?.jumlah_muatan || ""} required />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="" className="form-label">Proses</label>
                        <button type="button" className="btn btn-primary w-100" onClick={() => handleSubmit()} >SIMPAN PERUBAHAN</button>
                    </div>
                    <div className="col-md-3 col-sm-12 mb-3">
                        <label htmlFor="" className="form-label">Download</label>
                        <button type="button" className="btn btn-primary w-100" onClick={() => generatePDF()} >DOWNLOAD</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

DetailPage.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    detailId: PropTypes.number.isRequired,
    idAlokasi: PropTypes.number.isRequired,
    detailIdDriver: PropTypes.number.isRequired,
    detailIdArmada: PropTypes.number.isRequired,
    detailJenisMuatan: PropTypes.string.isRequired,
};

export default DetailPage;